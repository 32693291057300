import { createSelector } from '@reduxjs/toolkit';
import { ISection } from 'application/project/api/Connection';
import { selectProjectState } from 'redux/store';
import { sectionAdapter } from '../adapters/sectionAdapter';

export const {
  selectAll: selectAllSections,
  selectById: selectSectionByKey,
  selectEntities: selectSectionEntities,
} = sectionAdapter.getSelectors((state) => selectProjectState(state).sections);

export const makeSelectSectionsByConnection = () => {
  return createSelector(
    // First, pass one or more "input selector" functions:
    (state: any, valveConnectionKey: string, plenumConnectionIndex: number) => selectAllSections(state),
    (state: any, valveConnectionKey: string, plenumConnectionIndex: number) => valveConnectionKey,
    (state: any, valveConnectionKey: string, plenumConnectionIndex: number) => plenumConnectionIndex,
    // Then, an "output selector" that receives all the input results as arguments
    // and returns a final result value
    (sections, valveConnectionKey, plenumConnectionIndex) => {
      return sections.filter(
        (section) =>
          section.valveConnectionKey === valveConnectionKey && section.plenumConnectionIndex === plenumConnectionIndex
      ) as ISection[];
    }
  );
};
