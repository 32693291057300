import { configureStore } from '@reduxjs/toolkit';
import projectReducer from '../application/project/redux/projectSlice';
import componentReducer from '../application/components/redux/componentSlice';
import commonReducer from '../application/common/redux/commonSlice';
import editcompReducer from '../application/editcomp/redux/editcompSlice';
import { projectState } from '../application/project/redux/projectState';
import { componentState } from '../application/components/redux/componentState';
import { commonState } from '../application/common/redux/commonState';
import { compeditState } from 'application/editcomp/redux/editcompState';

export const store = configureStore({
  reducer: {
    project: projectReducer,
    component: componentReducer,
    common: commonReducer,
    editcomp: editcompReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
});

export default store;

export const selectProjectState = (state: any): projectState => {
  return state['project'];
};

export const selectComponentState = (state: any): componentState => {
  return state['component'];
};

export const selectCommonState = (state: any): commonState => {
  return state['common'];
};

export const selectCompEditState = (state: any): compeditState => {
  return state['editcomp'];
};
