import { createEntityAdapter } from '@reduxjs/toolkit';
import { ISection } from 'application/project/api/Connection';

export const sectionKeyIntern = (valveConnectionKey: string, plenumConnectionIndex: number, indexNumber: number) =>
  [valveConnectionKey, plenumConnectionIndex, indexNumber].join('_');

export const sectionKey = (section: ISection) =>
  sectionKeyIntern(section.valveConnectionKey, section.plenumConnectionIndex, section.indexNumber);

export const sectionAdapter = createEntityAdapter<ISection>({
  selectId: (section) => sectionKey(section),
  sortComparer: (a, b) => {
    let res = a.valveConnectionKey.localeCompare(b.valveConnectionKey);
    if (res === 0) res = a.plenumConnectionIndex - b.plenumConnectionIndex;
    if (res === 0) res = a.indexNumber - b.indexNumber;
    return res;
  },
});
