import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IBend {
  bendID: number;
  name: string;
  bendTypeID: number;
  ductID: number;
}

export const getBends = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/Bend', config);
  return response.data;
};
