import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BaseDialog, { BaseButtonType } from './BaseDialog';
import { InfoDialogState } from './useInfoDialog';

export interface IInfoDialogProps {
  IsOpen: boolean;
  onClose?: () => void;
  title: string;
  children?: any;
  fullWidth?: boolean;
  fullScreen?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export function InfoDialog(props: IInfoDialogProps) {
  const { t } = useTranslation(['translation']);
  return (
    <BaseDialog
      {...props}
      buttons={[
        {
          label: t('closebutton', 'close'),
          type: BaseButtonType.close,
        },
      ]}></BaseDialog>
  );
}

export const InfoDialogEx = (props: InfoDialogState) => {
  const { t } = useTranslation(['translation']);
  return (
    <BaseDialog
      IsOpen={props.isOpen}
      title={props.title}
      onClose={props.onClose}
      onApply={props.onClose}
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      fullScreen={props.fullScreen}
      buttons={[
        {
          label: t('closebutton', 'close'),
          type: BaseButtonType.close,
        },
      ]}>
      {props.content}
    </BaseDialog>
  );
};

export default InfoDialog;
