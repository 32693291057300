import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IValveConnectorEdit {
  valveConnectorID: number;
  name: string;
  valveDiameterID: number;
  valveDiameter: string;
  valveConnectionID: number;
  valveConnection: string;
  ductID: number;
  duct: string;
  enArticle: string;
  articleID: number;
  article: string;
  articleNumber: string;
  canDuplex: boolean;
}

export const getValveConnectorsEdit = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/ValveConnector/edit', config);
  return response.data;
};
