import { createSlice } from '@reduxjs/toolkit';
import { valveConnectorEditAdapter } from './adapters/valveConnectorEditAdapter';
import { valveConnectorGridEditAdapter } from './adapters/valveConnectorGridEditAdapter';
import { valveGridEditAdapter } from './adapters/valveGridEditAdapter';

import { initialState } from './editcompState';
import { fetchAsyncValveConnectorGridsEdit } from './thunks/fetchAsyncValveConnectorGridsEdit';
import { fetchAsyncValveConnectorsEdit } from './thunks/fetchAsyncValveConnectorsEdit';
import { fetchAsyncValveGridsEdit } from './thunks/fetchAsyncValveGridsEdit';
//  functions

export const editcompSlice = createSlice({
  name: 'editcomp',
  initialState,
  reducers: {
    Refresh(state, action) {
      // ✅ This "mutating" code is okay inside of createSlice!
      state.valveConnectorGrids.loading = 'init';
      state.valveConnectors.loading = 'init';
      state.valveGrids.loading = 'init';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncValveConnectorGridsEdit.pending, (state, action) => {
        state.valveConnectorGrids.loading = 'loading';
      })
      .addCase(fetchAsyncValveConnectorGridsEdit.fulfilled, (state, action) => {
        valveConnectorGridEditAdapter.setAll(state.valveConnectorGrids, action.payload);
        state.valveConnectorGrids.loading = 'idle';
      })
      .addCase(fetchAsyncValveConnectorsEdit.pending, (state, action) => {
        state.valveConnectors.loading = 'loading';
      })
      .addCase(fetchAsyncValveConnectorsEdit.fulfilled, (state, action) => {
        valveConnectorEditAdapter.setAll(state.valveConnectors, action.payload);
        state.valveConnectors.loading = 'idle';
      })
      .addCase(fetchAsyncValveGridsEdit.pending, (state, action) => {
        state.valveGrids.loading = 'loading';
      })
      .addCase(fetchAsyncValveGridsEdit.fulfilled, (state, action) => {
        valveGridEditAdapter.setAll(state.valveGrids, action.payload);
        state.valveGrids.loading = 'idle';
      });
  },
});

export default editcompSlice.reducer;
