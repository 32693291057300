import * as React from 'react';
import { FormatDecimal } from 'application/shared/format';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectFlowUnitID } from 'application/project/redux/selectors/projectSelector';
import { enFlowUnit } from 'application/project/api/Project';

export const useFlowRate = () => {
  const { t } = useTranslation();
  const flowUnitID = useSelector(selectFlowUnitID);

  const FormatAirFlowRate = React.useCallback(
    (value: number | undefined): string => {
      return FormatDecimal(flowUnitID === enFlowUnit.m3_h && value ? value * 3.6 : value, 1);
    },
    [flowUnitID]
  );

  const airFlowRateUnit = React.useCallback((): string => {
    return flowUnitID === enFlowUnit.m3_h ? t('flowunit_m3h', 'm3/h') : t('flowunit_ls', 'dm3/s');
  }, [flowUnitID, t]);

  const FormatAirFlowRateUnit = React.useCallback(
    (value: number | undefined): string => {
      return FormatAirFlowRate(value) + ' ' + airFlowRateUnit();
    },
    [FormatAirFlowRate, airFlowRateUnit]
  );
  return { FormatAirFlowRate, FormatAirFlowRateUnit, airFlowRateUnit };
};
