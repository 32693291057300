import * as React from 'react';

export interface EditOpenState {
  title: string;
  isOpen: boolean;
  content?: React.ReactNode;
  onApply: () => any;
  onClose: () => any;
  canApply?: () => boolean;
  applyDisabled?: boolean;
}

export interface openEditProps {
  title: string;
  content?: React.ReactNode;
  onApply: () => any;
  canApply?: () => boolean;
}

const useEditDialog = (): [EditOpenState, (props: openEditProps) => void] => {
  const [data, setData] = React.useState<{
    isOpen: boolean;
    title: string;
    content?: React.ReactNode;
    onApply: () => any;
    canApply?: () => boolean;
  }>({
    isOpen: false,
    title: '',
    content: undefined as React.ReactNode,
    onApply: () => {},
    canApply: undefined,
  });

  function SetOpen(props: openEditProps): void {
    setData({
      isOpen: true,
      title: props.title,
      content: props.content,
      onApply: props.onApply,
      canApply: props.canApply,
    });
  }
  function SetClose(): void {
    setData((prev) => {
      return {
        ...prev,
        isOpen: false,
      };
    });
  }

  let retVal: EditOpenState = {
    title: data.title,
    isOpen: data.isOpen,
    content: data.content,
    onApply: data.onApply,
    canApply: data.canApply,
    onClose: SetClose,
  };

  return [retVal, SetOpen];
};

export default useEditDialog;
