import React, { Component } from 'react';
import authService from 'components/api-authorization/AuthorizeService';
import Home from 'components/Home';
import { Redirect } from 'react-router-dom';
import { pages_project_list } from 'application/project/view/Edit/EditorGrid/useRoutes';

export class HomeWrapper extends Component {
  constructor(props) {
    super(props);

    this.preFlightIsAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    if (window.location.search.substring(1) === 'preflight=true') this.preFlightIsAuthenticated = true;

    this.state = {
      isAuthenticated: false,
      preFlightIsAuthenticatedValid: true,
      userName: null,
    };
  }
  preFlightIsAuthenticated = false;

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
    this.setState({
      isAuthenticated: isAuthenticated,
      preFlightIsAuthenticatedValid: false,
      userName: user && user.name,
    });
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }

  render() {
    const { isAuthenticated, preFlightIsAuthenticatedValid } = this.state;
    const preFlight = preFlightIsAuthenticatedValid && this.preFlightIsAuthenticated;
    return (
      <div className="container">
        <main>{isAuthenticated || preFlight ? <Redirect to={pages_project_list} push={false} /> : <Home />}</main>
      </div>
    );
  }
}
