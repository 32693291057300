import * as React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  })
);
const CheckBoxSelect = (props: {
  onChange: (value: number | boolean, field: string, error: string) => any;
  name: string;
  label: string;
  value: boolean;
  helperText?: string;
}) => {
  const { onChange } = props;
  const classes = useStyles();
  return (
    <FormControlLabel
      className="CheckBoxerLabel"
      control={
        <Checkbox
          className={clsx(classes.checkbox)}
          checked={props.value}
          onChange={React.useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked, props.name, ''),
            [onChange, props.name]
          )}
          name={props.name}
        />
      }
      label={props.label}
    />
    /*
    <CheckBoxer
      value={props.value}
      name={props.name}
      onChange={props.onChange}
      label={props.label}
      helperText={props.helperText}
    />
    */
  );
};

export default CheckBoxSelect;
