import { ISection, ISectionBend } from 'application/project/api/Connection';
import { projectSlice } from '../projectSlice';
import { updateConnectionThunk } from './AsyncConnectionUpdate';

export const SetSectionBendValue = (
  valveConnectionKey: string,
  section: ISection,
  bendID: number,
  amount: number | undefined
) => {
  return async (dispatch: any, getState: any) => {
    await dispatch(projectSlice.actions.updateSectionBend({ section: section, bendID: bendID, amount: amount }));
    await dispatch(updateConnectionThunk(valveConnectionKey));
  };
};
