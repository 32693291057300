import { createSelector } from '@reduxjs/toolkit';
import { enFlowUnit } from 'application/project/api/Project';
import { selectProjectState } from 'redux/store';

export const selectActiveProject = createSelector(selectProjectState, (projectState) => projectState.activeProject);

export const selectFlowUnitID = createSelector(
  (state: any) => selectProjectState(state).activeProject?.flowUnitID,
  (flowUnitID) => flowUnitID ?? enFlowUnit.m3_h
);
