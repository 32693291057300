import { DUCT_MAX_LENGTH, IApiPlenumConnection, IApiValveConnection } from 'application/project/api/Connection';
import { IPlenumData } from 'application/project/api/PlenumData';
import { componentState } from 'application/components/redux/componentState';
import { projectState } from 'application/project/redux/projectState';
import { valveConnectorGridAdapter } from 'application/components/redux/adapters/valveConnectorGridAdapter';
import { ductAdapter } from 'application/components/redux/adapters/ductAdapter';
import getDebietBorders from 'application/project/validate/bordersDebiet';
import getApiPlenumConnectionError from './getApiPlenumConnectionError';
import { valveConnectionAdapter } from '../redux/adapters/valveConnectionAdapter';
import { plenumAdapter } from 'application/components/redux/adapters/plenumAdapter';

const getApiValveConnectionError = (
  valveConnection: IApiValveConnection,
  plenumData: IPlenumData,
  experimental: boolean,
  projectState: projectState,
  componentState: componentState
): string => {
  if (!valveConnection.description) {
    return 'description';
  }
  if (!valveConnection.valveConnectorGridID) {
    return 'airflowRate';
  }
  if (!valveConnection.airflowRate || valveConnection.airflowRate <= 0) {
    return 'valveConnectorGridID';
  }

  const sumAirflowRate = valveConnectionAdapter
    .getSelectors()
    .selectAll(projectState.valveConnections)
    .filter((a) => a.valveConnectionKey === valveConnection.valveConnectionKey && a.supply === valveConnection.supply)
    .reduce((debiet, con) => debiet + (con.airflowRate || 0), 0);

  const plenum = plenumAdapter.getSelectors().selectById(componentState.plenums, plenumData?.plenumID || 0);
  if (!plenum) return 'plenum';
  if (!experimental && sumAirflowRate > plenum.maxFlowRate) return 'sumAirflowRate';

  const ductIDs = new Set(...valveConnection.plenumConnections.map((a) => a.sections.map((b) => b.ductID || 0)));
  const valveConnectorGrid = valveConnectorGridAdapter
    .getSelectors()
    .selectById(componentState.valveConnectorGrids, valveConnection.valveConnectorGridID);

  const ducts = ductAdapter.getSelectors().selectAll(componentState.ducts);

  let lenghtValid = true;
  for (let i = 0; i < valveConnection.plenumConnections.length; i++) {
    //alle lengte gevuld
    if (
      !valveConnection.plenumConnections[i].sections.reduce((valid, section) => {
        return valid === false || !section.ductLenght ? false : true;
      }, true)
    ) {
      return 'lenght not filled';
    }
    //totale lengte niet te lang?
    if (
      valveConnection.plenumConnections[i].sections.reduce((lenght, section) => lenght + (section.ductLenght || 0), 0) >
      DUCT_MAX_LENGTH
    ) {
      lenghtValid = false;
    }
  }
  valveConnection.plenumConnections.forEach((a) => {});
  if (!experimental && lenghtValid === false) return 'lenghtValid';

  const { ductMin, ductMax } = getDebietBorders(
    valveConnection.dual,
    valveConnection.supply,
    ducts.filter((a) => ductIDs.has(a.ductID)),
    valveConnectorGrid
  );

  if (!experimental && (valveConnection.airflowRate < ductMin - 0.1 || valveConnection.airflowRate > ductMax + 0.1))
    return 'max airflowRate ' + ductMin + ' < ' + valveConnection.airflowRate + ' < ' + ductMax;

  return valveConnection.plenumConnections.reduce(
    (prev: string, plenumConnection: IApiPlenumConnection) =>
      prev || getApiPlenumConnectionError(plenumConnection, plenumData, componentState),
    ''
  );
};

export default getApiValveConnectionError;
