import { createEntityAdapter } from '@reduxjs/toolkit';
import { IPlenumConnection } from 'application/project/api/Connection';

export const plenumConnectionKeyIntern = (valveConnectionKey: string, indexNumber: number) =>
  [valveConnectionKey, indexNumber].join('_');

export const plenumConnectionKey = (connection: IPlenumConnection) =>
  plenumConnectionKeyIntern(connection.valveConnectionKey, connection.indexNumber);

export const plenumConnectionAdapter = createEntityAdapter<IPlenumConnection>({
  selectId: (connection) => plenumConnectionKey(connection),
  sortComparer: (a, b) => {
    let res = a.valveConnectionKey.localeCompare(b.valveConnectionKey);
    if (res === 0) res = a.indexNumber - b.indexNumber;
    return res;
  },
});
