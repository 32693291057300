import RegularPage from 'components/controls/RegularPage';
import { InfoDialogEx } from 'components/dialog/InfoDialog';
import useInfoDialog, { OpenInfoDialogProps } from 'components/dialog/useInfoDialog';
import React from 'react';
import { useGridStateData } from '../controls/hooks/useGridStateData';
import ValveConnectorGridTable from './table/ValveConnectorGridTable';
import useValveConnectorGridData from './useValveConnectorGridData';

export interface IGridStateData {
  openInfoDialog: (openInfoDialogProps: OpenInfoDialogProps) => void;
}

export const initExpandedData = (
  openInfoDialog: (openInfoDialogProps: OpenInfoDialogProps) => void
): IGridStateData => {
  return {
    openInfoDialog: openInfoDialog,
  };
};
const ValveConnectorGrid = () => {
  const data = useValveConnectorGridData();
  const [infoDialogState, openInfoDialog] = useInfoDialog();
  const gridStatePlaceHolder = useGridStateData(initExpandedData(openInfoDialog));
  return (
    <RegularPage title="ValveConnectorGrid">
      <InfoDialogEx {...infoDialogState} />
      <div>ValveConnectorGrid</div>
      <ValveConnectorGridTable
        state={gridStatePlaceHolder}
        valveConnectors={data.valveConnectors}
        valveConnectorGrids={data.valveConnectorGrids}
        valveGrids={data.valveGrids}
        valveConnectorGridUpdated={data.valveConnectorGridUpdated}
      />
    </RegularPage>
  );
};

export default ValveConnectorGrid;
