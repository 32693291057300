import { createEntityAdapter } from '@reduxjs/toolkit';
import { ISectionBend } from 'application/project/api/Connection';

export const sectionBendKeyIntern = (
  valveConnectionKey: string,
  plenumConnectionIndex: number,
  indexNumber: number,
  bendID: number
) => [valveConnectionKey, plenumConnectionIndex, indexNumber, bendID].join('_');

export const sectionBendKey = (sectionBend: ISectionBend) =>
  sectionBendKeyIntern(
    sectionBend.valveConnectionKey,
    sectionBend.plenumConnectionIndex,
    sectionBend.sectionIndex,
    sectionBend.bendID
  );

export const sectionBendAdapter = createEntityAdapter<ISectionBend>({
  selectId: (section) =>
    [section.valveConnectionKey, section.plenumConnectionIndex, section.sectionIndex, section.bendID].join('_'),
});
