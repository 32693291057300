import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useObserver = (props: {
  callback: (
    borderBox: { width: number; height: number },
    contentBox: { width: number; height: number },
    target: any,
    entry: any
  ) => any;
  element: React.MutableRefObject<any>;
}) => {
  const { callback, element } = props;
  const current = element && element.current;

  const observer = React.useRef<ResizeObserver>();

  const myCallBack = React.useCallback(
    (entries: any[]) => {
      if (!entries?.length) return;
      let borderBox = {
        width: entries[0].borderBoxSize.inlineSize,
        height: entries[0].borderBoxSize.blockSize,
      };
      let contentBox = {
        width: entries[0].contentBoxSize.inlineSize,
        height: entries[0].contentBoxSize.blockSize,
      };
      // Checking for chrome as using a non-standard array
      if (entries[0].borderBoxSize[0]) {
        borderBox = {
          width: entries[0].borderBoxSize[0].inlineSize,
          height: entries[0].borderBoxSize[0].blockSize,
        };
      }
      // Checking for chrome as using a non-standard array
      if (entries[0].contentBoxSize[0]) {
        contentBox = {
          width: entries[0].contentBoxSize[0].inlineSize,
          height: entries[0].contentBoxSize[0].blockSize,
        };
      }
      callback(borderBox, contentBox, entries[0].target, entries[0]);
    },
    [callback]
  );

  React.useEffect(() => {
    let observedElement: any = undefined;
    const observe = () => {
      var obs: any = observer?.current;
      if (element && element.current && obs) {
        observedElement = element.current;
        obs.observe(element.current);
      }
    };

    // if we are already observing old element
    var obs: any = observer?.current;
    if (obs) {
      obs.unobserve(current);
    }
    observer.current = new ResizeObserver(myCallBack);
    observe();

    return () => {
      if (observedElement && obs) {
        obs.unobserve(observedElement);
      }
    };
  }, [current, element, myCallBack]);
};

export default useObserver;
