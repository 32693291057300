import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      marginTop: 16,
    },
  })
);

export const CheckBoxer = (props: {
  value: boolean | undefined;
  name: string;
  label?: string;
  helperText?: string;
  className?: string;
  onChange: (value: boolean, name: string, error: string) => any;
}) => {
  const { onChange } = props;
  const classes = useStyles();

  return (
    <FormControl variant="filled" className={props.className}>
      <InputLabel shrink={true} className="CheckBoxerLabel">
        {props.label}
      </InputLabel>
      <Checkbox
        className={clsx(classes.checkbox)}
        checked={props.value}
        onChange={React.useCallback(
          (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked, props.name, ''),
          [onChange, props.name]
        )}
        name={props.name}
      />
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};
