import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IValveGridEdit {
  valveGridID: number;
  name: string;
  enArticle: string;
  canSupply: boolean;
  canExtraction: boolean;
  flowSupplyMax: boolean;
  flowExtractionMax: boolean;
  plenumConnector: boolean;
  isCustom: boolean;
  onlyWall: boolean;
  articleID: number;
  article: string;
  articleNumber: string;
  gridImageID: number;
}

export const getValveGridsEdit = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/ValveGrid/edit', config);
  return response.data;
};
