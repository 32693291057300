import { IApiPlenumConnection, IApiSection } from 'application/project/api/Connection';
import { IPlenumData } from 'application/project/api/PlenumData';
import { componentState } from 'application/components/redux/componentState';
import { plenumAdapter } from 'application/components/redux/adapters/plenumAdapter';
import getApiSectionError from './getApiSectionError';

const getApiPlenumConnectionError = (
  plenumConnection: IApiPlenumConnection,
  plenumData: IPlenumData,
  componentState: componentState
): string => {
  const numberConnections =
    plenumAdapter.getSelectors().selectById(componentState.plenums, plenumData?.plenumID || 0)?.numberConnections || 0;

  if (
    !plenumConnection.connectionIndex ||
    plenumConnection.connectionIndex <= 0 ||
    plenumConnection.connectionIndex > numberConnections
  ) {
    return '';
  }
  //todo: controle min/max flowrate
  //todo: controle max duct lengte

  return plenumConnection.sections.reduce(
    (prev: string, section: IApiSection) => prev || getApiSectionError(section),
    ''
  );
};

export default getApiPlenumConnectionError;
