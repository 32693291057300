import { createSelector } from '@reduxjs/toolkit';
import { selectProjectState } from 'redux/store';
import { projectArticleAdapter } from '../adapters/projectArticleAdapter';

export const {
  selectAll: selectAllProjectArticles,
  selectById: selectProjectArticleById,
  selectEntities: selectProjectArticleEntities,
} = projectArticleAdapter.getSelectors((state) => selectProjectState(state).projectArticles);

export const projectArticlesLoading = createSelector(
  (state: any) => selectProjectState(state).projectArticles,
  (articles) => !articles.valid || articles.loading === 'loading'
);
