import * as React from 'react';

export interface IKeyboardNavigation {
  data: any;
  name: string;
  event: any;
}

export interface IRefCallBack {
  data: any;
  name: string;
  node: any;
}

export interface IUsesKeyboardNavigation {
  refCallBack: (arg: IRefCallBack) => any;
  onKeyboardNavigation: (arg: IKeyboardNavigation) => any;
}

export const useKeyboardNavigation = (props: {
  rowdatas: any[];
  getFieldID?: (item: any) => number | string;
  getNextFieldID?: (arg: IKeyboardNavigation, direction: number, refsNodeRefs: any) => number | string;
}): IUsesKeyboardNavigation => {
  const refsNodeRefs = React.useRef<any>({});

  const refData = React.useRef(props.rowdatas);
  if (refData.current !== props.rowdatas) {
    refData.current = props.rowdatas;
    refsNodeRefs.current = {};
  }

  const getFieldID = (data: any): number | string => {
    return props.getFieldID ? props.getFieldID(data) : data;
  };

  const refCallBack = (arg: IRefCallBack) => {
    if (!refsNodeRefs.current[arg.name]) {
      refsNodeRefs.current[arg.name] = {};
    }
    refsNodeRefs.current[arg.name][getFieldID(arg.data)] = arg.node;
  };

  const onKeyboardNavigation = (arg: IKeyboardNavigation) => {
    let nextFieldID: number | string | undefined = undefined;

    const direction = arg.event.key === 'ArrowUp' ? -1 : arg.event.key === 'ArrowDown' ? 1 : 0;
    if (direction === 0) return;
    arg.event.preventDefault();

    if (props.getNextFieldID) {
      nextFieldID = props.getNextFieldID(arg, direction, refsNodeRefs.current);
    } else {
      const fieldID = getFieldID(arg.data);
      const rowIndex = props.rowdatas.findIndex((a) => getFieldID(a) === fieldID);

      if (rowIndex + direction >= 0 && rowIndex + direction < props.rowdatas.length) {
        nextFieldID = getFieldID(props.rowdatas[rowIndex + direction]);
      }
    }
    if (nextFieldID) {
      if (refsNodeRefs.current[arg.name]) {
        const input = refsNodeRefs.current[arg.name][nextFieldID];
        if (input) {
          input.focus();
        }
      }
    }
  };
  return {
    onKeyboardNavigation: onKeyboardNavigation,
    refCallBack: refCallBack,
  };
};
