import {
  ComponentEntityState,
  initialComponentEntityState,
  initialLoadingEntityState,
  LoadingEntityState,
} from 'redux/shared';
import { IValveConnectorEdit } from '../api/ValveConnectorGrid/ValveConnectorEdit';
import { IValveConnectorGridEdit } from '../api/ValveConnectorGrid/ValveConnectorGridEdit';
import { IValveGridEdit } from '../api/ValveConnectorGrid/ValveGridEdit';
import { valveConnectorEditAdapter } from './adapters/valveConnectorEditAdapter';
import { valveConnectorGridEditAdapter } from './adapters/valveConnectorGridEditAdapter';
import { valveGridEditAdapter } from './adapters/valveGridEditAdapter';

export interface compeditState {
  valveConnectorGrids: LoadingEntityState<IValveConnectorGridEdit>;
  valveConnectors: ComponentEntityState<IValveConnectorEdit>;
  valveGrids: ComponentEntityState<IValveGridEdit>;
}

export const initialState: compeditState = {
  valveConnectorGrids: valveConnectorGridEditAdapter.getInitialState(initialLoadingEntityState),
  valveConnectors: valveConnectorEditAdapter.getInitialState(initialComponentEntityState),
  valveGrids: valveGridEditAdapter.getInitialState(initialComponentEntityState),
};
