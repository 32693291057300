import * as React from 'react';

export interface InfoDialogState {
  title: string;
  isOpen: boolean;
  content: React.ReactNode;
  onClose: () => any;
  fullWidth?: boolean;
  fullScreen?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | undefined;
}

export interface OpenInfoDialogProps {
  title: string;
  content: React.ReactNode;
  fullWidth?: boolean;
  fullScreen?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | undefined;
}

const useInfoDialog = (): [
  InfoDialogState,
  (props: OpenInfoDialogProps) => void
] => {
  const [data, setData] = React.useState<{
    isOpen: boolean;
    title: string;
    content: React.ReactNode;
    fullWidth: boolean | undefined;
    fullScreen: boolean | undefined;
    maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | undefined;
  }>({
    isOpen: false,
    title: '',
    content: undefined,
    fullWidth: undefined,
    fullScreen: undefined,
    maxWidth: undefined,
  });

  function SetOpen(newstate: OpenInfoDialogProps): void {
    setData({
      isOpen: true,
      title: newstate.title,
      content: newstate.content,
      fullWidth: newstate.fullWidth,
      fullScreen: newstate.fullScreen,
      maxWidth: newstate.maxWidth,
    });
  }
  function SetClose(): void {
    setData((prev) => {
      return {
        ...prev,
        isOpen: false,
      };
    });
  }

  let retVal: InfoDialogState = {
    title: data.title,
    isOpen: data.isOpen,
    content: data.content,
    fullWidth: data.fullWidth,
    fullScreen: data.fullScreen,
    maxWidth: data.maxWidth,
    onClose: SetClose,
  };

  return [retVal, SetOpen];
};

export default useInfoDialog;
