import Grid from '@material-ui/core/Grid';
import { IProject, updateProject } from 'application/project/api/Project';
import { projectSlice } from 'application/project/redux/projectSlice';
import { selectActiveProject } from 'application/project/redux/selectors/projectSelector';
import EditorPage from 'components/controls/EditorPage';
import TextInputField from 'components/controls/TextInputField';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DuctTypeSelector } from './DuctTypeSelector';
import { VentilationSystemSelector } from './VentilationSystemSelector';
import afbeelding_round from 'image/settings/rond.png';
import afbeelding_oval from 'image/settings/ovaal.png';
import afbeelding_balans from 'image/settings/balans.png';
import afbeelding_extract from 'image/settings/extract.png';
import { editorStyles } from '../editorStyles';
import useInfoDialog from 'components/dialog/useInfoDialog';
import { InfoDialogEx } from 'components/dialog/InfoDialog';
import nameof from 'ts-nameof.macro';
import { valveConnectionAdapter } from 'application/project/redux/adapters/valveConnectionAdapter';
import { selectCommonState, selectProjectState } from 'redux/store';
import { PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import { eppDataComponentAdapter } from 'application/project/redux/adapters/eppDataComponentAdapter';
import { useTranslation } from 'react-i18next';
import DateInputField from 'components/controls/DateInputField';
import { ValveDiameterSelector } from './ValveDiameterSelector';
import { FlowUnitSelector } from './FlowUnitSelector';
import { CheckBoxer } from 'components/controls/CheckBoxer';
import { RoleName } from 'application/common/redux/commonState';

const ProjectSettings = (props: { onNext: () => any }) => {
  const dispatch = useDispatch();
  const projectState = useSelector((state) => selectProjectState(state));
  const roles = useSelector((state) => selectCommonState(state).user?.roles) || ([] as string[]);
  const project = useSelector(selectActiveProject);

  const projectRef = React.useRef(project);
  projectRef.current = project;

  const [infoDialog, setInfoDialogOpen] = useInfoDialog();
  const classes = editorStyles();
  const { t } = useTranslation(['project']);

  const changeField = React.useCallback(
    (value: string | number | boolean | undefined, field: string, error: string) => {
      if (
        (field === nameof<IProject>((o) => o.ventilationTypeID) && value === 2) ||
        field === nameof<IProject>((o) => o.ductTypeID)
      ) {
        const connectors = valveConnectionAdapter.getSelectors().selectAll(projectState.valveConnections);
        const eppdatas = eppDataComponentAdapter.getSelectors().selectAll(projectState.eppdatacomponents);

        if (field === nameof<IProject>((o) => o.ventilationTypeID) && value === 2) {
          //we want change to extraction only
          //is is only allowed when there is no supply plenum
          const supplyConnections = connectors.reduce(
            (val, a) => (a.supply === PlenumDataSupply.supply ? val + 1 : val),
            0
          );
          const supplyeppdata = eppdatas.reduce(
            (val, a) => (a.supply === PlenumDataSupply.supply && a.amount && a.amount > 0 ? val + a.amount : val),
            0
          );

          if (supplyConnections > 0 || supplyeppdata > 0) {
            setInfoDialogOpen({
              content:
                t('errorChangeVentilationType', 'change to extraction not possible') +
                (supplyConnections > 0
                  ? t('errorChangeVentilationTypeSupplyConnections', ', there are supply connections')
                  : '') +
                (supplyeppdata > 0
                  ? t('errorChangeVentilationTypeSupplyEppData', ', there are supply connectionmaterials')
                  : ''),
              title: t('errorChangeVentilationTypeTitle', 'change ventilation type'),
            });
            return;
          }
        }
        if (field === nameof<IProject>((o) => o.ductTypeID) && connectors.length > 0) {
          setInfoDialogOpen({
            content: t('errorChangeDuctType', 'change duct type not possible, there are supply connections'),
            title: t('errorChangeDuctTypeTitle', 'change duct type'),
          });
          return;
        }
      }
      if (projectRef.current) {
        const newProject = {
          ...projectRef.current,
          [field]: value,
        };
        projectRef.current = newProject;

        updateProject(newProject).then((result) => {
          dispatch(projectSlice.actions.activeProjectChanged(result as IProject));
        });
      }
    },
    [dispatch, projectState, setInfoDialogOpen, t]
  );

  return (
    <EditorPage title={t('projectsettingstitle', 'project settings')} onNext={props.onNext}>
      <InfoDialogEx {...infoDialog} />
      <Grid container spacing={3}>
        <Grid item sm={8}>
          <TextInputField
            label={t('title', 'title')}
            name={nameof<IProject>((o) => o.title)}
            fullWidth
            required
            debounce={true}
            value={project?.title}
            onChange={changeField}
            helperText=" "
          />
        </Grid>
        <Grid item sm={4}>
          <DateInputField
            label={t('date', 'date')}
            name={nameof<IProject>((o) => o.date)}
            fullWidth
            required
            debounce={false}
            value={project?.date}
            onChange={changeField}
            helperText=" "
          />
        </Grid>
      </Grid>
      <div className={classes.TextBlockHost}>
        <TextInputField
          label={t('address', 'address')}
          name={nameof<IProject>((o) => o.address)}
          fullWidth
          multiline
          rows={4}
          debounce={true}
          value={project?.address}
          onChange={changeField}
          helperText=" "
        />
      </div>
      <div className={classes.TextBlockHost}>
        <TextInputField
          label={t('advizer', 'advizer')}
          name={nameof<IProject>((o) => o.advizer)}
          fullWidth
          multiline
          rows={4}
          debounce={true}
          value={project?.advizer}
          onChange={changeField}
          helperText=" "
        />
      </div>
      <div className={classes.TextBlockHost}>
        <TextInputField
          label={t('description', 'description')}
          name={nameof<IProject>((o) => o.description)}
          fullWidth
          multiline
          rows={4}
          debounce={true}
          value={project?.description}
          onChange={changeField}
          helperText=" "
        />
      </div>
      <Grid container>
        <Grid item xs={6} sm={2}>
          <div>
            <DuctTypeSelector
              name={nameof<IProject>((o) => o.ductTypeID)}
              value={project?.ductTypeID}
              onChange={changeField}
            />
          </div>
        </Grid>
        <Grid item xs={6} sm={2} className={classes.imageContainer}>
          <img
            className={classes.image}
            src={project?.ductTypeID === 1 ? afbeelding_round : project?.ductTypeID === 3 ? afbeelding_oval : ''}
            alt=""
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <VentilationSystemSelector
            name={nameof<IProject>((o) => o.ventilationTypeID)}
            value={project?.ventilationTypeID}
            onChange={changeField}
          />
        </Grid>
        <Grid item xs={6} sm={2} className={classes.imageContainer}>
          <img
            className={classes.image}
            src={
              project?.ventilationTypeID === 1
                ? afbeelding_balans
                : project?.ventilationTypeID === 2
                ? afbeelding_extract
                : ''
            }
            alt=""
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <ValveDiameterSelector
            name={nameof<IProject>((o) => o.valveDiameterID)}
            value={project?.valveDiameterID}
            onChange={changeField}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <FlowUnitSelector
            name={nameof<IProject>((o) => o.flowUnitID)}
            value={project?.flowUnitID}
            onChange={changeField}
          />
        </Grid>
      </Grid>

      {roles.includes(RoleName[RoleName.Experimental]) ? (
        <Grid container>
          <Grid item xs={6} sm={2}>
            <div>
              <CheckBoxer
                name={nameof<IProject>((o) => o.experimental)}
                value={project?.experimental}
                onChange={changeField}
                label={t('experimental', 'experimental')}
              />
            </div>
          </Grid>
        </Grid>
      ) : null}
    </EditorPage>
  );
};

//const commonState = useSelector(selectCommonState);

export default ProjectSettings;
