import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { Container } from 'reactstrap';
import { selectCommonState } from 'redux/store';
import EditComponentItems, { linkitem } from './SelectEditComponent/MenuKeuzes';
import SelectEditComponent from './SelectEditComponent/SelectEditComponent';

const parent = '/EditComponent';
const MapRoute = (items: linkitem[]) => {
  const commonState = useSelector(selectCommonState);
  return items.map((item) =>
    commonState.user?.roles.includes(item.role) ? (
      <div key={item.to}>
        <Route path={parent + '/' + item.to} component={item.component} />
      </div>
    ) : null
  );
};

const EditComponentRoutes = () => {
  return (
    <Container>
      <React.Fragment>
        {MapRoute(EditComponentItems)}
        <Route path={parent} exact component={SelectEditComponent} />
      </React.Fragment>
    </Container>
  );
};

export default EditComponentRoutes;
