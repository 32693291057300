import * as React from 'react';

export interface CorfirmOpenState {
  title: string;
  isOpen: boolean;
  question: React.ReactNode;
  onConfirm: () => any;
  onClose: () => any;
}

export interface openCorfirmProps {
  title: string;
  question: string;
  onConfirm: () => any;
}

const useConfirmDialog = (): [
  CorfirmOpenState,
  (props: openCorfirmProps) => void
] => {
  const [data, setData] = React.useState({
    isOpen: false,
    title: '',
    question: undefined as React.ReactNode,
    onConfirm: () => {},
  });

  function SetOpen(props: openCorfirmProps): void {
    setData({
      isOpen: true,
      title: props.title,
      question: props.question,
      onConfirm: props.onConfirm,
    });
  }
  function SetClose(): void {
    setData((prev) => {
      return {
        ...prev,
        isOpen: false,
      };
    });
  }

  let retVal: CorfirmOpenState = {
    title: data.title,
    isOpen: data.isOpen,
    question: data.question,
    onConfirm: data.onConfirm,
    onClose: SetClose,
  };

  return [retVal, SetOpen];
};

export default useConfirmDialog;
