import * as React from 'react';
import { ISection, IValveConnection } from 'application/project/api/Connection';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeSelectBends } from 'application/components/redux/selectors/bendSelector';
import connectionStyles from './controls/connectionStyles';
import PartDuctElbow from './PartDuctElbow';

const PartDuctElbows = (props: { connection: IValveConnection; section: ISection }) => {
  const classes = connectionStyles();
  const { section } = props;
  const selectBends = React.useMemo(makeSelectBends, []);
  const bends = useSelector((state) => selectBends(state, section.ductID));
  return (
    <div className={clsx(classes.partBlock)}>
      {bends.map((bend, index) => (
        <div key={bend.bendID} className={clsx(index === 0 ? classes.halfLeft : classes.halfRight)}>
          <PartDuctElbow bend={bend} section={section} />
        </div>
      ))}
    </div>
  );
};

export default PartDuctElbows;
