import React from 'react';
import { tableStyles } from './tableStyles';
import clsx from 'clsx';

interface ITableProps {
  className?: string;
  children?: any;
  title?: string;
}

const Table = (props: ITableProps) => {
  const tableClasses = tableStyles();

  return (
    <table
      title={props.title}
      className={clsx(tableClasses.table, tableClasses.tableBorder, tableClasses.BorderLeft, props.className)}>
      {props.children}
    </table>
  );
};

export default Table;
