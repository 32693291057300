import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px) !important`,
        marginLeft: drawerWidth,
      },
      boxShadow: 'unset',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    menuToolbar: {
      borderBottomColor: theme.palette.secondary.main,
      borderBottomStyle: 'solid',
      borderBottomWidth: 4,
    },
    List: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },

    menu: {
      '&:hover': {
        textDecoration: 'none',
      },
    },

    logo: {
      maxHeight: 64,
    },

    logoHost: {
      textAlign: 'center',
    },

    menuIcon: {
      minWidth: 40,
      marginRight: theme.spacing(1),
      display: 'inline',
      '&.active': {
        color: theme.palette.primary.contrastText,
      },
      textAlign: 'center',
    },
    menuItem: {
      paddingLeft: 2,
      paddingRight: theme.spacing(1),
      paddingTop: 2,
      borderBottomColor: '#ddd',
      borderBottomWidth: 2,
      borderBottomStyle: 'solid',
      color: theme.palette.primary.main,
      '&.active': {
        color: theme.palette.secondary.main,
      },
    },
    menuText: {
      '&.noIcon': {
        color: theme.palette.text.primary,
        marginLeft: theme.spacing(1),
        '&.active': {
          color: theme.palette.secondary.main,
        },
      },
    },
  })
);
