import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { withTranslation } from 'react-i18next';

const LanguageSelectNavItem = (props) => {
  const [language, setLanguage] = React.useState(props.currentLanguage);
  React.useEffect(() => setLanguage(props.currentLanguage), [props.currentLanguage]);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    props.onChangeLanguage(lang);
  };

  return (
    <NavItem className="select_languages">
      <div className="selectdiv">
        <label>
          <select
            className="select_languages_"
            onChange={(e) => changeLanguage(e.currentTarget.value)}
            value={language}>
            {props.languages.map((language) => (
              <option key={language} value={language}>
                {language.toUpperCase()}
              </option>
            ))}
          </select>
        </label>
      </div>
    </NavItem>
  );
};

class LoginMenuTranslated extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null,
      contactName: null,
      newsLetter: null,
      roles: [],
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
    this.setState({
      isAuthenticated,
      userName: user && user.name,
      contactName: user && user.contactName,
      roles: user && user.role ? user.role : [],
    });
  }

  render() {
    const { isAuthenticated, contactName } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
      return this.authenticatedView(contactName, profilePath, logoutPath);
    }
  }

  authenticatedView(userName, profilePath, logoutPath) {
    const { t } = this.props;
    return (
      <Fragment>
        <NavItem>
          <NavLink tag={Link} to={profilePath}>
            {t('menuhello', 'hello')} {userName}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="" tag={Link} to={logoutPath}>
            {t('menulogout', 'Logout')}
          </NavLink>
        </NavItem>
        {this.state.roles && this.state.roles.includes('EditComponent') ? (
          <NavItem>
            <NavLink className="" tag={Link} to={'/EditComponent'}>
              {t('menumaintenance ', 'Maintenance')}
            </NavLink>
          </NavItem>
        ) : null}
        <LanguageSelectNavItem
          onChangeLanguage={this.props.onChangeLanguage}
          currentLanguage={this.props.currentLanguage}
          languages={this.props.languages}
        />
      </Fragment>
    );
  }

  anonymousView(registerPath, loginPath) {
    const { t } = this.props;
    return (
      <Fragment>
        <NavItem>
          <NavLink tag={Link} to={registerPath}>
            {t('menuRegister', 'Register')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={loginPath}>
            {t('menuLogin', 'Login')}
          </NavLink>
        </NavItem>
        <LanguageSelectNavItem
          onChangeLanguage={this.props.onChangeLanguage}
          currentLanguage={this.props.currentLanguage}
          languages={this.props.languages}
        />
      </Fragment>
    );
  }
}

export const LoginMenu = withTranslation('IdentitySystem')(LoginMenuTranslated);
