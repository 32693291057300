import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';
import { PlenumDataSupply } from 'application/shared/PlenumDataSupply';

export interface IPlenumData {
  supply: PlenumDataSupply;
  plenumID?: number;
  airflowRate?: number;
  workPresure?: number;
  activeConnectionKey?: string;
}

export const getPlenumDatas = async (guid: string) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/plenumdata/' + guid, config);
  return response.data;
};

export const getPlenumData = async (guid: string, supply: PlenumDataSupply) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/plenumdata/' + guid + '/' + supply, config);
  return response.data;
};

export const updatePlenumData = async (guid: string, supply: PlenumDataSupply, plenumdata: IPlenumData) => {
  const config = await bearerTokenHeader();
  const response = await axios.put('api/plenumdata/' + guid + '/' + supply, plenumdata, config);
  return response.data;
};
