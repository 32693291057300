import * as React from 'react';
import TableProp from './TableProp';

const useTableProps = (
  rowCount: number,
  topOffset: number,
  columnCount: number,
  leftOffset: number
): [number[], (index: number, height: number) => void, number[], (index: number, width: number) => void] => {
  const tableProps = React.useRef(new TableProp(rowCount, topOffset, columnCount, leftOffset));
  const [StickyRowTop, setStickyRowHeight] = React.useState<number[]>(tableProps.current.StickyRowHeight);
  const [StickyColumnWidth, setStickyColumnWidth] = React.useState<number[]>(tableProps.current.StickyColumnWidth);

  const setRowHeight = (index: number, height: number) => {
    if (tableProps.current.rows[index].stickyHeight !== height) {
      tableProps.current.rows[index].stickyHeight = height;
      setStickyRowHeight(tableProps.current.StickyRowHeight);
    }
  };

  const setColumnWidth = (index: number, width: number) => {
    if (tableProps.current.columns[index].stickyWidth !== width) {
      tableProps.current.columns[index].stickyWidth = width;
      setStickyColumnWidth(tableProps.current.StickyColumnWidth);
    }
  };

  return [StickyRowTop, setRowHeight, StickyColumnWidth, setColumnWidth];
};

export default useTableProps;
