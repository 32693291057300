import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './EditorGrid/styles';
import ApplicationBar from './EditorGrid/ApplicationBar';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { pages_project_list, pages_project_setactive, useRoutes } from './EditorGrid/useRoutes';
import clsx from 'clsx';
import { asyncInitEditor } from 'application/project/redux/thunks/AsyncInitEditor';
import { selectActiveProject } from 'application/project/redux/selectors/projectSelector';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

//import mainLogo from 'image/mggroup.png';
import mainLogo from 'image/burgerhout.png';

import authService from 'components/api-authorization/AuthorizeService';
import EditorSwitch from './EditorGrid/EditorSwitch';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

//https://material-ui.com/components/drawers/#drawer

export const Editor = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [roles, setRoles] = React.useState<string[]>([]);
  const location = useLocation();

  React.useEffect(() => {
    authService.getRoles().then((resRoles) => {
      if (componentIsMounted.current) setRoles(resRoles);
    });
  }, []);

  const componentIsMounted = React.useRef(true);
  React.useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []); // Using an empty dependency array ensures this only runs on unmount

  React.useEffect(() => {
    //initieren van de editor
    dispatch(asyncInitEditor());
  }, [dispatch]);

  const project = useSelector(selectActiveProject);

  const [theEditorTabs, editorTabs, , image, onPrev, canPrev, onNext, canNext] = useRoutes(
    project?.ventilationTypeID === 2,
    roles
  );

  const handleDrawerToggle = React.useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  if (!project) {
    if ((localStorage.activeProjectGuid || '').length > 0) {
      return (
        <Redirect
          to={
            pages_project_setactive + localStorage.activeProjectGuid + '?url=' + encodeURIComponent(location.pathname)
          }
          push={false}
        />
      );
    } else {
      return <Redirect to={pages_project_list} push={false} />;
    }
  }

  const drawer = (
    <div>
      <a href={pages_project_list}>
        <div className={(classes.toolbar, classes.logoHost)}>
          <img src={mainLogo} className={classes.logo} alt={'logo'} />
        </div>
      </a>
      {editorTabs.map((tabGroup, index2) => {
        return (
          <React.Fragment key={'group' + index2}>
            {index2 === 0 ? <Divider /> : null}
            <List className={classes.List}>
              {tabGroup.map((item, index) => {
                const active = location.pathname.toLowerCase() === item.url.toLowerCase() ? 'active' : null;
                const visible =
                  item.icon ||
                  (item.urlGroup && location.pathname.toLowerCase().startsWith(item.urlGroup.toLowerCase()));

                const mainItem = (
                  <Link to={item.url} key={item.title} className={clsx(classes.menu, active)}>
                    <ListItem button key={item.title} className={clsx(classes.menuItem, active)}>
                      <ListItemIcon className={clsx(classes.menuIcon, active)}>
                        <img src={item.icon} alt={''} />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        className={clsx(classes.menuText, active, item.icon ? null : 'noIcon')}
                      />
                      {active ? <ChevronRightIcon /> : null}
                    </ListItem>
                  </Link>
                );

                return visible ? mainItem : null;
              })}
            </List>
          </React.Fragment>
        );
      })}
      {image ? <img style={{ width: '100%' }} alt="map" src={image} /> : null}
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ApplicationBar
        project={project}
        handleDrawerToggle={handleDrawerToggle}
        canPrev={canPrev}
        onPrev={onPrev}
        onNext={onNext}
        canNext={canNext}
      />

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar}></div>
        <div className="tabs">
          <EditorSwitch
            theEditorTabs={theEditorTabs}
            image={image}
            canPrev={canPrev}
            onPrev={onPrev}
            onNext={onNext}
            canNext={canNext}
          />
        </div>
      </main>
    </div>
  );
};

export default Editor;
