import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IDuctAdapter {
  ductAdapterID: number;
  name: string;
  ductID1: number;
  ductID2: number;
}

export const getDuctAdapters = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/DuctAdapter', config);
  return response.data;
};
