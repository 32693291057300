import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IEppTerminal {
  eppTerminalID: number;
  name: string;
  eppTerminalTypeID: number;
  eppColorID: number;
  eppDiameterID: number;
}

export const getEppTerminals = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/EppTerminal', config);
  return response.data;
};
