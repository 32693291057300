import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEppTypes } from 'application/components/api/EppType';
import { selectComponentState } from 'redux/store';

export const fetchAsyncEppTypes = createAsyncThunk(
  'components/fetchAsyncEppType',
  async (data: {}, thunkAPI) => {
    const response = await getEppTypes();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectComponentState(thunkAPI.getState()).eppTypes.loading === 'init';
    },
  }
);
