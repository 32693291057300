import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IExtraComponent {
  extraComponentID: number;
  name: string;
  imageID: number;
  extraComponentGroupID?: number;
  extraComponentGroupImageID?: number;
  extraComponentOrder?: number;
  extraComponentGroupOrder?: number;
}

export const getExtraComponents = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/ExtraComponent', config);
  return response.data;
};
