import { IValveConnectorEdit } from 'application/editcomp/api/ValveConnectorGrid/ValveConnectorEdit';
import { IValveConnectorGridEdit } from 'application/editcomp/api/ValveConnectorGrid/ValveConnectorGridEdit';
import { IValveGridEdit } from 'application/editcomp/api/ValveConnectorGrid/ValveGridEdit';
import { IGridStatePlaceHolder } from '../../controls/hooks/useGridStateData';
import { IGridStateData } from '../ValveConnectorGrid';

export interface IRenderRowCallBacks {
  valveConnectorName: (valveConnector: IValveConnectorEdit | undefined) => any;
  article: (valveConnector: IValveConnectorEdit | undefined) => any;
  duplex: (valveConnector: IValveConnectorEdit | undefined) => any;
  duct: (valveConnector: IValveConnectorEdit | undefined) => any;
  valveConnection: (valveConnector: IValveConnectorEdit | undefined) => any;
  valveDiameter: (valveConnector: IValveConnectorEdit | undefined) => any;
  valveConnectorGridConnector: (valveConnector: IValveConnectorEdit | undefined, valveGrid: IValveGridEdit) => any;
}

export const RenderRow = (
  state: IGridStatePlaceHolder<IGridStateData>,
  rowCount: number,
  callBacks: IRenderRowCallBacks,
  valveConnector: IValveConnectorEdit | undefined,
  valveGrids: IValveGridEdit[],
  valveConnectorGrids: IValveConnectorGridEdit[]
) => {
  let result: any[] = [];
  result.push(callBacks.valveConnectorName(valveConnector));
  result.push(callBacks.article(valveConnector));
  result.push(callBacks.duct(valveConnector));
  result.push(callBacks.valveDiameter(valveConnector));
  result.push(callBacks.valveConnection(valveConnector));
  result.push(callBacks.duplex(valveConnector));

  valveGrids.forEach((valveGrid) => {
    result.push(callBacks.valveConnectorGridConnector(valveConnector, valveGrid));
  });
  return result;
};
