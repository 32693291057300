import * as React from 'react';
import { useTranslation } from 'react-i18next';
import NumberInputField from './NumberInputField';
import { validateNoError } from './TextInputField';

export default function AmountInputField(props: {
  value: number | undefined;
  name: string;
  label?: string;
  onChange: (value: number | undefined, name: string, error: string) => any;
  readOnly?: boolean;
  fullWidth?: boolean;
  className?: string;
  maxAmount?: number;
  required?: boolean;
}) {
  const { onChange } = props;

  const { t } = useTranslation();
  const helperText = React.useMemo(() => t('helpamountunit', 'amount (pcs)'), [t]);

  const validate = React.useCallback(
    (val: number) => {
      if (val < 0) return { error: helperText, warn: '' };
      if (props.maxAmount && val > props.maxAmount) return { error: helperText, warn: '' };
      return validateNoError();
    },
    [props.maxAmount, helperText]
  );

  return (
    <NumberInputField
      label={props.label}
      value={props.value}
      onChange={onChange}
      required={props.required}
      debounce
      helperText={helperText}
      zeroAsEmpty
      type="int"
      className={props.className}
      readOnly={props.readOnly}
      fullWidth={props.fullWidth}
      name={props.name}
      validate={validate}
    />
  );
}
