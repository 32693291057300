import * as React from 'react';
import {
  copyProject,
  createProject,
  deleteProject,
  getProjectObject,
  IProject,
  updateProject,
} from 'application/project/api/Project';

enum saveStatusType {
  ready,
  error,
  saving,
  success,
}

export interface IUseEditProjectProps {
  handleSubmitEdit: () => any;
  handleSubmitCopy: () => any;
  handleSubmitDelete: () => any;
  changeField: (value: string, field: string, error: string) => any;
  canSubmit: () => boolean;
  cancelEdit: () => any;
  setProject: (project?: IProject) => any;
}

export const useEditProject = (
  saveCallback: (project: IProject) => any,
  deleteCallback: (project: IProject) => any
): [IProject, IUseEditProjectProps] => {
  const [saveStatus, setSaveStatusIntern] = React.useState<saveStatusType>(saveStatusType.ready);
  const [project, setProject] = React.useState<IProject>(getProjectObject());
  const projectRef = React.useRef(project);
  const saveStatusRef = React.useRef(saveStatus);

  const setSaveStatus = React.useCallback(
    (status: saveStatusType) => {
      setSaveStatusIntern(status);
      saveStatusRef.current = status;
    },
    [setSaveStatusIntern]
  );

  const handleSubmitEdit = React.useCallback(() => {
    if (projectRef.current) {
      setSaveStatus(saveStatusType.saving);

      if (projectRef.current.guid) {
        updateProject(projectRef.current).then((result) => {
          setSaveStatus(saveStatusType.ready);
          saveCallback(result);
        });
      } else {
        createProject(projectRef.current).then((result) => {
          setSaveStatus(saveStatusType.ready);
          saveCallback(result);
        });
      }
    }
  }, [setSaveStatus, saveCallback]);

  const handleSubmitCopy = React.useCallback(() => {
    if (projectRef.current) {
      setSaveStatus(saveStatusType.saving);

      copyProject(projectRef.current).then((result) => {
        setSaveStatus(saveStatusType.ready);
        saveCallback(result);
      });
    }
  }, [setSaveStatus, saveCallback]);

  const handleSubmitDelete = React.useCallback(() => {
    if (projectRef.current) {
      setSaveStatus(saveStatusType.saving);
      deleteProject(projectRef.current).then((result) => {
        setSaveStatus(saveStatusType.ready);
        deleteCallback(projectRef.current);
      });
    }
  }, [setSaveStatus, deleteCallback]);

  const changeField = React.useCallback((value: string, field: string, error: string) => {
    switch (field) {
      case 'title':
      case 'address':
      case 'description':
        setProject((prev: IProject) => {
          projectRef.current = {
            ...prev,
            [field]: value,
          };
          return projectRef.current;
        });
        break;
    }
  }, []);

  const canSubmit = React.useCallback(() => {
    return projectRef.current.title.length > 0 && saveStatusRef.current !== saveStatusType.saving;
  }, []);

  const cancelEdit = React.useCallback(() => {}, []);

  const setExternProject = React.useCallback(
    (project?: IProject) => {
      projectRef.current = getProjectObject(project);
      setProject(projectRef.current);
    },
    [setProject]
  );

  return [
    project,
    {
      changeField: changeField,
      handleSubmitEdit: handleSubmitEdit,
      handleSubmitCopy: handleSubmitCopy,
      handleSubmitDelete: handleSubmitDelete,
      canSubmit: canSubmit,
      cancelEdit: cancelEdit,
      setProject: setExternProject,
    },
  ];
};

export default useEditProject;
