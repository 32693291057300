export enum RoleName {
  EditComponent,
  Calculator,
  SystemAdmin,
  Experimental,
}

export interface commonState {
  user?: {
    contactName: string;
    userEmail: string;
    userID: string;
    language: string;
    roles: string[];
    //flowUnitID: enFlowUnit;
    expires_at: string;
    expires_in: number;
  };

  pageTitle: string;
  pageSubTitle: string;
}

export const initialState: commonState = {
  user: undefined,
  pageTitle: '',
  pageSubTitle: '',
};
