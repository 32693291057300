import * as React from 'react';

export interface IGridStatePlaceHolder<T> {
  state: T;
  changeGridStateData: (field: string, value: boolean, index?: number | string) => any;
}

export const useGridStateData = <T>(defaultValue: T) => {
  const [gridStateData, setGridStateData] = React.useState<T>(defaultValue);
  const changeGridStateData = React.useMemo(() => {
    return (field: string, value: boolean, index?: number | string) => {
      setGridStateData((prev) => {
        const retVal: any = { ...prev };
        if (index) {
          retVal[field][index] = value;
        } else {
          retVal[field] = value;
        }
        return retVal;
      });
    };
  }, []);

  const gridStatePlaceHolder: IGridStatePlaceHolder<T> = React.useMemo(() => {
    return {
      state: gridStateData,
      changeGridStateData: changeGridStateData,
    };
  }, [gridStateData, changeGridStateData]);

  return gridStatePlaceHolder;
};
