import TableProp from './TableProp';

export default class ColumnProp {
  constructor(table: TableProp, index: number) {
    this._table = table;
    this._stickWidth = 0;
    this._index = index;
  }
  private _table: TableProp;

  private _index: number;
  get index(): number {
    return this._index;
  }

  private _stickWidth: number;
  get stickyWidth(): number {
    return this._stickWidth;
  }
  set stickyWidth(newVal: number) {
    this._stickWidth = newVal;
  }

  get stickyLeft(): number {
    let top = 0;
    for (let i = 0; i < this.index; i++) {
      top += this._table.columns[i]._stickWidth;
    }
    return top;
  }
}
