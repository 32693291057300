import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IValveConnectorGridEdit {
  valveConnectorGridID: number;
  valveConnectorID: number;
  valveConnector: string;
  valveGridID: number;
  valveGrid: string;

  supplyZeta: number;
  supplyFlowMax: number;
  supplyZetaDuplex: number;
  supplyFlowMaxDuplex: number;

  extractionZeta: number;
  extractionFlowMax: number;
  extractionZetaDuplex: number;
  extractionFlowMaxDuplex: number;

  imageID: number;
}

export const getValveConnectorGridsEdit = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/ValveConnectorGrid/edit', config);
  return response.data;
};

export const updateValveConnectorGridsEdit = async (grid: IValveConnectorGridEdit) => {
  const config = await bearerTokenHeader();
  const response = await axios.post('api/ValveConnectorGrid/edit', grid, config);
  return response.data;
};
