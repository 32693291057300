import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const editorStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      height: 100,
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },

    imageContainer: {
      padding: theme.spacing(1),
    },

    TextBlockHost: {
      //paddingBottom: theme.spacing(1),
    },
  })
);
