import { IApiSection, IApiSectionBend } from 'application/project/api/Connection';

const getSectionBendError = (sectionbend: IApiSectionBend): string => {
  if (!sectionbend.amount || sectionbend.amount <= 0) {
    return 'sectionbend.amount';
  }
  if (!sectionbend.bendID) {
    return 'bendID';
  }

  return '';
};

const getApiSectionError = (section: IApiSection): string => {
  if (!section.ductLenght || section.ductLenght <= 0) {
    return 'ductLenght';
  }
  if (!section.ductID) {
    return 'ductID';
  }
  return section.bends.reduce((prev: string, bend: IApiSectionBend) => prev || getSectionBendError(bend), '');
};

export default getApiSectionError;
