import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEppMaterials } from 'application/components/api/EppMaterial';
import { selectComponentState } from 'redux/store';

export const fetchAsyncEppMaterials = createAsyncThunk(
  'components/fetchAsyncEppMaterial',
  async (data: {}, thunkAPI) => {
    const response = await getEppMaterials();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectComponentState(thunkAPI.getState()).eppMaterials.loading === 'init';
    },
  }
);
