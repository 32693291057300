import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProject, getProjects, IProject } from 'application/project/api/Project';
import { projectSlice } from '../projectSlice';
import { fetchAsyncConnections } from './AsyncConnection';
import { fetchAsyncEppDatas } from './AsyncEppData';
import { fetchAsyncEppDataComponents } from './AsyncEppDataComponent';
import { fetchAsyncPlenumDatas } from './AsyncPlenumData';
import { fetchAsyncProjectExtraComponents } from './AsyncProjectExtraComponent';

export const fetchAsyncProject = createAsyncThunk(
  'project/getAsyncProject',
  async (data: { guid: string }, thunkAPI) => {
    const response = await getProject(data.guid);
    return response;
  }
);

export const fetchAsyncProjects = createAsyncThunk('project/fetchAsyncProjects', async (data: {}, thunkAPI) => {
  const response = await getProjects();
  return response;
});

export const asyncSetActiveProject = (project: IProject) => {
  return (dispatch: any, getState: any) => {
    localStorage.activeProjectGuid = project.guid;
    dispatch(projectSlice.actions.activeProjectChanged(project));
    dispatch(fetchAsyncPlenumDatas({ guid: project.guid }));
    dispatch(fetchAsyncConnections({ guid: project.guid }));
    dispatch(fetchAsyncEppDatas({ guid: project.guid }));
    dispatch(fetchAsyncEppDataComponents({ guid: project.guid }));
    dispatch(fetchAsyncProjectExtraComponents({ guid: project.guid }));
  };
};
