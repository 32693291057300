import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import LocizeBackend from 'i18next-locize-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

import { format as formatDate, isDate } from 'date-fns';
import { nl, de, fr, it } from 'date-fns/locale'; // import all locales we need

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
export const locales = { nl, de, fr, it }; // used to look up the required locale

i18n
  // connnection to Locize
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(ChainedBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['home', 'translation', 'projectlist', 'IdentitySystem', 'project', 'common'],
    //defaultNS: 'home',
    fallbackLng: 'en',
    debug: false,
    saveMissing: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (isDate(value)) {
          try {
            const locale = locales[lng];
            if (format === 'shortdate') return formatDate(value, 'P', { locale });
            if (format === 'shorttime') return formatDate(value, 'p', { locale });
            return formatDate(value, format, { locale });
          } catch (e) {
            return e.message;
          }
        }
      },
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    backend: {
      backends: [LocalStorageBackend, LocizeBackend],
      backendOptions: [
        {
          expirationTime: 0,
          //expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
        },
        {
          // the id of your locize project
          projectId: '3e10c6be-ffb3-4a15-92a4-1f0820d18373',
          // add an api key if you want to send missing keys
          apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
          // the reference language of your project
          referenceLng: 'en',
          // version - defaults to latest
          //NOG NIET version: '[VERSION]',
          // private - set to true if you version on locize is set to use private publish
          private: false,
          // hostnames that are allowed to create, update keys
          // please keep those to your local system, staging, test servers (not production)
          // can be array of allowed hosts or a function (hostname) => { return true; // or false if not allowed }
          allowedAddOrUpdateHosts: ['localhost', 'hybalanstest.burgerhout.com'],
          // optional event triggered on saved to backend
          //NOG NIET # onSaved: (lng, ns) => { ... },
          // can be used to reload resources in a specific interval (useful in server environments)
          //NOG NIET # reloadInterval: typeof window !== 'undefined' ? false : 60 * 60 * 1000};
        },
      ],
    },
  });

//leuk testje
/*  
i18n.on('missingKey', function (lngs, namespace, key, res) {
  console.log('Marcel', key);
});
*/

i18n.on('initialized', (options) => {
  //console.log('initialized', options);
  i18n.loadNamespaces('editortabs', 'roomeditor', 'eppedit', 'componentlist', 'installationplan');
});

export default i18n;
