import * as React from 'react';
import { useWindowSize } from 'application/shared/useWindowSize';
import connectionStyles from './parts/controls/connectionStyles';

const RenderParts = (props: {
  partPlenumConnections: JSX.Element;
  partDescription: JSX.Element;
  partDebiet: JSX.Element;
  partSections: JSX.Element[][];
  partValve: JSX.Element;
}) => {
  const classes = connectionStyles();
  const windowSize = useWindowSize();

  const { partPlenumConnections, partDescription, partDebiet, partSections, partValve } = props;

  switch (windowSize) {
    case 'xs':
    case 'sm':
      return (
        <React.Fragment>
          {partPlenumConnections}
          {partDescription}
          {partDebiet}
          {partSections.map((row) => (
            <React.Fragment>{row.map((cell) => cell)}</React.Fragment>
          ))}
          {partValve}
        </React.Fragment>
      );
    case 'md':
    case 'lg':
      return (
        <table className={classes.tableStyle}>
          <tbody>
            <tr>
              <td className={classes.tableCell2}>{partPlenumConnections}</td>
              <td className={classes.tableCell2} rowSpan={3}>
                {partValve}
              </td>
            </tr>
            <tr>
              <td className={classes.tableCell2}>{partDescription}</td>
            </tr>
            <tr>
              <td className={classes.tableCell2}>{partDebiet}</td>
            </tr>
            {partSections.map((row, index) => (
              <tr key={index}>
                {row.map((cell, index2) => (
                  <td key={index2} className={classes.tableCell2}>
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    case 'xl':
    case 'xxl':
      return (
        <table className={classes.tableStyle}>
          <tbody>
            <tr>
              <td className={classes.tableCell3}>{partPlenumConnections}</td>
              <td className={classes.tableCell3}></td>
              <td className={classes.tableCell3} rowSpan={2}>
                {partValve}
              </td>
            </tr>
            <tr>
              <td className={classes.tableCell3}>{partDescription}</td>
              <td className={classes.tableCell3}>{partDebiet}</td>
            </tr>
            {partSections.map((row, index) => (
              <tr key={index}>
                {row.map((cell, index2) => (
                  <td key={index2} className={classes.tableCell2}>
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
  }
};

export default RenderParts;
