import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BaseDialog, { BaseButtonType } from './BaseDialog';
import { EditOpenState } from './useEditDialog';

interface MyEditOpenState extends EditOpenState {
  children?: React.ReactNode;
}

export default function EditDialog(props: MyEditOpenState) {
  const { t } = useTranslation(['translation']);
  return (
    <BaseDialog
      IsOpen={props.isOpen}
      title={props.title}
      onClose={props.onClose}
      onApply={() => {
        props.onClose();
        props.onApply();
      }}
      buttons={[
        {
          label: t('cancelbutton', 'cancel'),
          type: BaseButtonType.close,
        },
        {
          label: t('applybutton', 'apply changes'),
          type: BaseButtonType.submit,
          disabled: props.canApply !== undefined ? !props.canApply() : props.applyDisabled,
        },
      ]}>
      <div>
        {props.content}
        {props.children}
      </div>
    </BaseDialog>
  );
}
