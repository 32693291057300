import React from 'react';
import { NavMenu } from './NavMenu';
import { useLocation } from 'react-router-dom';
import { pages_project_list } from 'application/project/view/Edit/EditorGrid/useRoutes';

export const Layout = (props: {
  children: any;
  onChangeLanguage: (language: string) => any;
  languages: string[];
  currentLanguage: string;
}) => {
  const location = useLocation();
  //static displayName = Layout.name;
  return (
    <div>
      {location.pathname.toLowerCase().startsWith('/project') &&
      !location.pathname.toLowerCase().startsWith(pages_project_list) ? null : (
        <NavMenu
          onChangeLanguage={props.onChangeLanguage}
          currentLanguage={props.currentLanguage}
          languages={props.languages}
          homePage={location.pathname === '/' || location.pathname.toLowerCase().startsWith(pages_project_list)}
        />
      )}
      {props.children}
    </div>
  );
};
