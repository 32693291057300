import RowProp from './RowProp';
import ColumnProp from './ColumnProp';

export default class TableProp {
  constructor(
    rowCount: number,
    topOffset: number,
    columnCount: number,
    leftOffset: number
  ) {
    this._rowCount = rowCount;
    this._columnCount = columnCount;
    this._topOffset = topOffset;
    this._leftOffset = leftOffset;
    this._rows = [];
    for (let i = 0; i < this._rowCount; i++) {
      this._rows.push(new RowProp(this, i));
    }

    this._columns = [];
    for (let i = 0; i < this._columnCount; i++) {
      this._columns.push(new ColumnProp(this, i));
    }
  }

  private _topOffset: number;
  private _leftOffset: number;
  private _rowCount: number;
  private _columnCount: number;
  private _rows: RowProp[];
  private _columns: ColumnProp[];
  get rowCount(): number {
    return this._rowCount;
  }
  get columnCount(): number {
    return this._columnCount;
  }
  get rows(): RowProp[] {
    return this._rows;
  }
  get columns(): ColumnProp[] {
    return this._columns;
  }

  get StickyRowHeight(): number[] {
    let offset = this._topOffset;
    let values: number[] = [];
    for (let i = 0; i < this._rowCount; i++) {
      values.push(offset);
      offset += this.rows[i].stickyHeight;
    }
    return values;
  }
  get StickyColumnWidth(): number[] {
    let offset = this._leftOffset;
    let values: number[] = [];
    for (let i = 0; i < this._columnCount; i++) {
      values.push(offset);
      offset += this.columns[i].stickyWidth;
    }
    return values;
  }
}
