import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';
import download from 'downloadjs';

export const getInstallationPlanUrl = (guid: string | undefined) => {
  return guid ? 'api/InstallationPlan/' + guid + '/0' : '';
};

export const getInstallationPlanHtml = async (guid: string) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/InstallationPlan/' + guid + '/html', config);
  return response.data;
};

export const getInstallationCalcultionHtml = async (guid: string) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/InstallationPlan/' + guid + '/calculation', config);
  return response.data;
};

export const getInstallationPlanPdf = async (guid: string) => {
  const config = await bearerTokenHeader();
  (config as any).responseType = 'blob';
  const response = await axios.get('api/InstallationPlan/' + guid + '/pdf', config);
  const content = response.headers['content-type'];
  download(response.data, 'InstallationPlan.pdf', content);
  //return response.data;
};

export const getInstallationPlanXlsx = async (guid: string) => {
  const config = await bearerTokenHeader();
  (config as any).responseType = 'blob';
  const response = await axios.get('api/CompListExcel/' + guid, config);
  const content = response.headers['content-type'];
  download(response.data, 'InstallationPlan.xlsx', content);
  //return response.data;
};
