import { createSlice } from '@reduxjs/toolkit';
import { bendAdapter } from './adapters/bendAdapter';
import { ductAdapter } from './adapters/ductAdapter';
import { ductAdapterAdapter } from './adapters/ductAdapterAdapter';
import { eppColorAdapter } from './adapters/eppColorAdapter';
import { eppTerminalTypeAdapter } from './adapters/eppTerminalTypeAdapter';
import { eppDiameterAdapter } from './adapters/eppDiameterAdapter';
import { eppMaterialAdapter } from './adapters/eppMaterialAdapter';
import { eppTerminalAdapter } from './adapters/eppTerminalAdapter';
import { eppComponentAdapter } from './adapters/eppComponentAdapter';
import { eppTypeAdapter } from './adapters/eppTypeAdapter';
import { extraComponentAdapter } from './adapters/extraComponentAdapter';
import { plenumAdapter } from './adapters/plenumAdapter';
//import { valveAdapter } from './adapters/valveAdapter';
import { valveConnectorGridAdapter } from './adapters/valveConnectorGridAdapter';
import { initialState } from './componentState';
import { fetchAsyncBends } from './thunks/fetchAsyncBends';
import { fetchAsyncDuctAdapters } from './thunks/fetchAsyncDuctAdapters';
import { fetchAsyncDucts } from './thunks/fetchAsyncDucts';
import { fetchAsyncEppColors } from './thunks/fetchAsyncEppColors';
import { fetchAsyncEppComponents } from './thunks/fetchAsyncEppComponents';
import { fetchAsyncEppDiameters } from './thunks/fetchAsyncEppDiameters';
import { fetchAsyncEppMaterials } from './thunks/fetchAsyncEppMaterials';
import { fetchAsyncEppTerminals } from './thunks/fetchAsyncEppTerminals';
import { fetchAsyncEppTerminalTypes } from './thunks/fetchAsyncEppTerminalTypes';
import { fetchAsyncEppTypes } from './thunks/fetchAsyncEppTypes';
import { fetchAsyncExtraComponents } from './thunks/fetchAsyncExtraComponents';
import { fetchAsyncPlenums } from './thunks/fetchAsyncPlenums';
import { fetchAsyncValveConnectorGrids } from './thunks/fetchAsyncValveConnectorGrids';
import { fetchAsyncEppDampers } from './thunks/fetchAsyncEppDamper';
import { fetchAsyncEppDamperTypes } from './thunks/fetchAsyncEppDamperTypes';
import { eppDamperAdapter } from './adapters/eppDamperAdapter';
import { eppDamperTypeAdapter } from './adapters/eppDamperTypeAdapter';
//import { fetchAsyncValves } from './thunks/fetchAsyncValves';

//  functions

export const componentSlice = createSlice({
  name: 'component',
  initialState,
  reducers: {
    LanguageChanged(state, action) {
      // ✅ This "mutating" code is okay inside of createSlice!
      state.bends.loading = 'init';
      state.eppColors.loading = 'init';
      state.eppComponents.loading = 'init';
      state.eppDiameters.loading = 'init';
      state.eppMaterials.loading = 'init';
      state.eppTerminals.loading = 'init';
      state.eppTerminalTypes.loading = 'init';
      state.eppTypes.loading = 'init';
      state.extraComponents.loading = 'init';
      state.ductadapters.loading = 'init';
      state.ducts.loading = 'init';
      state.plenums.loading = 'init';
      state.valveConnectorGrids.loading = 'init';
    },
  },
  extraReducers: (builder) => {
    builder
      //bends
      .addCase(fetchAsyncBends.pending, (state, action) => {
        state.bends.loading = 'loading';
      })
      .addCase(fetchAsyncBends.fulfilled, (state, action) => {
        bendAdapter.setAll(state.bends, action.payload);
        state.bends.loading = 'idle';
      })
      //EppColors
      .addCase(fetchAsyncEppColors.pending, (state, action) => {
        state.eppColors.loading = 'loading';
      })
      .addCase(fetchAsyncEppColors.fulfilled, (state, action) => {
        eppColorAdapter.setAll(state.eppColors, action.payload);
        state.eppColors.loading = 'idle';
      })
      //EppComponents
      .addCase(fetchAsyncEppComponents.pending, (state, action) => {
        state.eppComponents.loading = 'loading';
      })
      .addCase(fetchAsyncEppComponents.fulfilled, (state, action) => {
        eppComponentAdapter.setAll(state.eppComponents, action.payload);
        state.eppComponents.loading = 'idle';
      })
      //EppDiameters
      .addCase(fetchAsyncEppDiameters.pending, (state, action) => {
        state.eppDiameters.loading = 'loading';
      })
      .addCase(fetchAsyncEppDiameters.fulfilled, (state, action) => {
        eppDiameterAdapter.setAll(state.eppDiameters, action.payload);
        state.eppDiameters.loading = 'idle';
      })
      //EppMaterials
      .addCase(fetchAsyncEppMaterials.pending, (state, action) => {
        state.eppMaterials.loading = 'loading';
      })
      .addCase(fetchAsyncEppMaterials.fulfilled, (state, action) => {
        eppMaterialAdapter.setAll(state.eppMaterials, action.payload);
        state.eppMaterials.loading = 'idle';
      })
      //EppTerminals
      .addCase(fetchAsyncEppTerminals.pending, (state, action) => {
        state.eppTerminals.loading = 'loading';
      })
      .addCase(fetchAsyncEppTerminals.fulfilled, (state, action) => {
        eppTerminalAdapter.setAll(state.eppTerminals, action.payload);
        state.eppTerminals.loading = 'idle';
      })
      //EppTerminalTypes
      .addCase(fetchAsyncEppTerminalTypes.pending, (state, action) => {
        state.eppTerminalTypes.loading = 'loading';
      })
      .addCase(fetchAsyncEppTerminalTypes.fulfilled, (state, action) => {
        eppTerminalTypeAdapter.setAll(state.eppTerminalTypes, action.payload);
        state.eppTerminalTypes.loading = 'idle';
      })
      //EppDampers
      .addCase(fetchAsyncEppDampers.pending, (state, action) => {
        state.eppDampers.loading = 'loading';
      })
      .addCase(fetchAsyncEppDampers.fulfilled, (state, action) => {
        eppDamperAdapter.setAll(state.eppDampers, action.payload);
        state.eppDampers.loading = 'idle';
      })
      //EppDamperTypes
      .addCase(fetchAsyncEppDamperTypes.pending, (state, action) => {
        state.eppDamperTypes.loading = 'loading';
      })
      .addCase(fetchAsyncEppDamperTypes.fulfilled, (state, action) => {
        eppDamperTypeAdapter.setAll(state.eppDamperTypes, action.payload);
        state.eppDamperTypes.loading = 'idle';
      })
      //EppTypes
      .addCase(fetchAsyncEppTypes.pending, (state, action) => {
        state.eppTypes.loading = 'loading';
      })
      .addCase(fetchAsyncEppTypes.fulfilled, (state, action) => {
        eppTypeAdapter.setAll(state.eppTypes, action.payload);
        state.eppTypes.loading = 'idle';
      })
      //ExtraComponents
      .addCase(fetchAsyncExtraComponents.pending, (state, action) => {
        state.extraComponents.loading = 'loading';
      })
      .addCase(fetchAsyncExtraComponents.fulfilled, (state, action) => {
        extraComponentAdapter.setAll(state.extraComponents, action.payload);
        state.extraComponents.loading = 'idle';
      })
      //ductAdapters
      .addCase(fetchAsyncDuctAdapters.pending, (state, action) => {
        state.ductadapters.loading = 'loading';
      })
      .addCase(fetchAsyncDuctAdapters.fulfilled, (state, action) => {
        ductAdapterAdapter.setAll(state.ductadapters, action.payload);
        state.ductadapters.loading = 'idle';
      })
      //ducs
      .addCase(fetchAsyncDucts.pending, (state, action) => {
        state.ducts.loading = 'loading';
      })
      .addCase(fetchAsyncDucts.fulfilled, (state, action) => {
        ductAdapter.setAll(state.ducts, action.payload);
        state.ducts.loading = 'idle';
      })
      //plenums
      .addCase(fetchAsyncPlenums.pending, (state, action) => {
        state.plenums.loading = 'loading';
      })
      .addCase(fetchAsyncPlenums.fulfilled, (state, action) => {
        plenumAdapter.setAll(state.plenums, action.payload);
        state.plenums.loading = 'idle';
      })
      //valveConnectorGrids
      .addCase(fetchAsyncValveConnectorGrids.pending, (state, action) => {
        state.valveConnectorGrids.loading = 'loading';
      })
      .addCase(fetchAsyncValveConnectorGrids.fulfilled, (state, action) => {
        valveConnectorGridAdapter.setAll(state.valveConnectorGrids, action.payload);
        state.valveConnectorGrids.loading = 'idle';
      });
  },
});

export default componentSlice.reducer;
