import * as React from 'react';
import clsx from 'clsx';
import { GridInput, gridInputStyles, IGridInputProps } from '../input/GridInput';
import { IUsesKeyboardNavigation } from '../hooks/useKeyboardNavigation';
import Td from './Td';

interface ITdGridInputProps extends IGridInputProps {
  tdClassName?: string;
  keyboardNavigation?: IUsesKeyboardNavigation;
}
export const GridInputTd = (props: ITdGridInputProps) => {
  const classes = gridInputStyles();
  const newClassName = clsx(props.tdClassName, classes.gridtd, 'GridInputTd');
  const { keyboardNavigation, onKeyboardNavigation, row, refCallBack } = props;

  const onKeyboardNavigationMemo = React.useMemo(() => {
    return (name: string, event: any) => {
      if (onKeyboardNavigation) return onKeyboardNavigation(name, event);
      else if (keyboardNavigation?.onKeyboardNavigation) {
        keyboardNavigation?.onKeyboardNavigation({
          data: row,
          name: name,
          event: event,
        });
      }
    };
  }, [keyboardNavigation, onKeyboardNavigation, row]);

  const myRefCallBack = React.useMemo(() => {
    return (name: string, node: any) => {
      if (refCallBack) return refCallBack;
      else if (keyboardNavigation?.refCallBack)
        keyboardNavigation?.refCallBack({
          data: row,
          name: name,
          node: node,
        });
    };
  }, [refCallBack, keyboardNavigation, row]);

  const gridClassName = clsx(classes.inputcell, classes.inputTdChild);

  return (
    <Td className={newClassName}>
      <GridInput
        {...props}
        className={gridClassName}
        onKeyboardNavigation={onKeyboardNavigationMemo}
        refCallBack={myRefCallBack}
      />
    </Td>
  );
};
