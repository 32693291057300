import axios from 'axios';
import authService from './AuthorizeService';
import i18n from 'i18next';

//https://medium.com/@franciscopa91/how-to-implement-oidc-authentication-with-react-context-api-and-react-router-205e13f2d49
//https://medium.com/@danny.garcia_19872/creating-your-own-global-identity-provider-part-i-4688bd28e1b3

//https://developer.okta.com/blog/2017/07/25/oidc-primer-part-1

//claims uitgelegd
//https://korzh.com/blog/aspnet-identity-store-user-data-in-claims

const bearerTokenHeader = async () => {
  const token = await authService.getAccessToken();
  if (!token) {
    throw new Error('token is null');
  }

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  return {
    headers: { Authorization: `Bearer ${token}` },
    params: { lng: i18n.language },
  };
};

export const TestenAuthService = async () => {
  //const token = await authService.getAccessToken();
  const user = await authService.getRawUser();

  if (user) {
    //const hours = Math.floor(user.expires_in / 3600);
    //const min = Math.floor((user.expires_in - hours * 3600) / 60);
    //const sec = Math.floor(user.expires_in - hours * 3600 - min * 60);
    /*console.log('TestenAuthService', {
      token: token,
      user: user,
      expires_at: user ? new Date(user.expires_at * 1000) : null,
      expires_in: user?.expires_in,
      expires_in2:
        hours + ':' + ('0' + min).substr(('0' + min).length - 2) + ':' + ('0' + sec).substr(('0' + sec).length - 2),
      profile: user.profile,
    });*/
  }
  /*
   */
};

export default bearerTokenHeader;
