import { createAsyncThunk } from '@reduxjs/toolkit';
import { getExtraComponents } from 'application/components/api/ExtraComponent';
import { selectComponentState } from 'redux/store';

export const fetchAsyncExtraComponents = createAsyncThunk(
  'components/fetchAsyncExtraComponents',
  async (data: {}, thunkAPI) => {
    const response = await getExtraComponents();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectComponentState(thunkAPI.getState()).extraComponents.loading === 'init';
    },
  }
);
