import React from 'react';
import Settings from '../Settings/Settings';
import ValveConnectorGrid from '../ValveConnectorGrid/ValveConnectorGrid';

export interface linkitem {
  to: string;
  title: string;
  role: string;
  component: React.ComponentType<any>;
}

export const EditComponentItems: linkitem[] = [
  {
    to: 'ValveConnectorGrid',
    title: 'ValveConnectorGrid',
    role: 'EditComponent',
    component: ValveConnectorGrid,
  },
  {
    to: 'Settings',
    title: 'Settings',
    role: 'SystemAdmin',
    component: Settings,
  },
];

export default EditComponentItems;
