import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IEppTerminalType {
  eppTerminalTypeID: number;
  name: string;
}

export const getEppTerminalTypes = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/EppTerminalType', config);
  return response.data;
};
