import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiValveConnection, IValveConnection, updateConnection } from 'application/project/api/Connection';
import { IPlenumData } from 'application/project/api/PlenumData';
import { selectComponentState, selectProjectState } from 'redux/store';
import { valveConnectionAdapter } from '../adapters/valveConnectionAdapter';
import { plenumDataAdapter } from '../adapters/plenumDataAdapter';
import GetApiConnection from './helper/GetApiConnection';
import getApiValveConnectionError from 'application/project/validate/getApiValveConnectionError';

export const updateAsyncConnection = createAsyncThunk(
  'project/connection/updateAsyncConnection',
  async (data: { guid: string; apiConnection: IApiValveConnection }, thunkAPI) => {
    const response = await updateConnection(data.guid, data.apiConnection);
    return {
      plenumData: response.plenumData as IPlenumData,
      connections: response.connections as IApiValveConnection[],
    };
  }
);

export const updateConnectionThunk = (valveConnectionKey: string) => {
  return async (dispatch: any, getState: any) => {
    const projectState = selectProjectState(getState());
    const componentState = selectComponentState(getState());
    if (!projectState.activeProject) return;
    const experimental = projectState.activeProject.experimental;
    const valveConnection = valveConnectionAdapter
      .getSelectors()
      .selectById(projectState.valveConnections, valveConnectionKey);
    if (!valveConnection) return;
    const plenumData = plenumDataAdapter.getSelectors().selectById(projectState.plenumdatas, valveConnection.supply);
    if (!plenumData) return;

    const apiConnection: IApiValveConnection = GetApiConnection(valveConnection, projectState, componentState);

    const valid = getApiValveConnectionError(apiConnection, plenumData, experimental, projectState, componentState);
    if (!valid) {
      await dispatch(
        updateAsyncConnection({
          guid: projectState.activeProject?.guid,
          apiConnection: apiConnection,
        })
      );
    } else {
      console.log(valid);
    }
  };
};
