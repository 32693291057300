import TableProp from './TableProp';

export default class RowProp {
  constructor(table: TableProp, index: number) {
    this._table = table;
    this._stickyHeight = 0;
    this._index = index;
  }

  private _table: TableProp;

  private _index: number;
  get index(): number {
    return this._index;
  }

  private _stickyHeight: number;
  get stickyHeight(): number {
    return this._stickyHeight;
  }
  set stickyHeight(newVal: number) {
    //if (newName && newName.length > fullNameMaxLength) {
    //throw new Error('fullName has a max length of ' + fullNameMaxLength);
    //}
    this._stickyHeight = newVal;
  }

  get stickyTop(): number {
    let top = 50;
    for (let i = 0; i < this.index; i++) {
      top += this._table.rows[i]._stickyHeight;
    }
    return top;
  }
}
