import { fetchAsyncBends } from 'application/components/redux/thunks/fetchAsyncBends';
import { fetchAsyncDuctAdapters } from 'application/components/redux/thunks/fetchAsyncDuctAdapters';
import { fetchAsyncDucts } from 'application/components/redux/thunks/fetchAsyncDucts';
import { fetchAsyncEppColors } from 'application/components/redux/thunks/fetchAsyncEppColors';
import { fetchAsyncEppComponents } from 'application/components/redux/thunks/fetchAsyncEppComponents';
import { fetchAsyncEppDampers } from 'application/components/redux/thunks/fetchAsyncEppDamper';
import { fetchAsyncEppDamperTypes } from 'application/components/redux/thunks/fetchAsyncEppDamperTypes';
import { fetchAsyncEppDiameters } from 'application/components/redux/thunks/fetchAsyncEppDiameters';
import { fetchAsyncEppMaterials } from 'application/components/redux/thunks/fetchAsyncEppMaterials';
import { fetchAsyncEppTerminals } from 'application/components/redux/thunks/fetchAsyncEppTerminals';
import { fetchAsyncEppTerminalTypes } from 'application/components/redux/thunks/fetchAsyncEppTerminalTypes';
import { fetchAsyncEppTypes } from 'application/components/redux/thunks/fetchAsyncEppTypes';
import { fetchAsyncExtraComponents } from 'application/components/redux/thunks/fetchAsyncExtraComponents';
import { fetchAsyncPlenums } from 'application/components/redux/thunks/fetchAsyncPlenums';
import { fetchAsyncValveConnectorGrids } from 'application/components/redux/thunks/fetchAsyncValveConnectorGrids';

export const asyncInitEditor = () => {
  return (dispatch: any, getState: any) => {
    //plenum selectie
    dispatch(fetchAsyncPlenums({}));

    //room editor
    dispatch(fetchAsyncDucts({}));
    dispatch(fetchAsyncBends({}));
    //dispatch(fetchAsyncValves({}));
    dispatch(fetchAsyncValveConnectorGrids({}));
    dispatch(fetchAsyncDuctAdapters({}));

    //epp materials
    dispatch(fetchAsyncEppColors({}));
    dispatch(fetchAsyncEppComponents({}));
    dispatch(fetchAsyncEppDiameters({}));
    dispatch(fetchAsyncEppMaterials({}));
    dispatch(fetchAsyncEppTerminals({}));
    dispatch(fetchAsyncEppTerminalTypes({}));
    dispatch(fetchAsyncEppDampers({}));
    dispatch(fetchAsyncEppDamperTypes({}));
    dispatch(fetchAsyncEppTypes({}));

    //extra components
    dispatch(fetchAsyncExtraComponents({}));
  };
};
