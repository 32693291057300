import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IProjectExtraComponent {
  projectExtraComponentID: number;
  extraComponentID: number;
  amount?: number;
}

export const getProjectExtraComponents = async (guid: string) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/ProjectExtraComponent/' + guid, config);
  return response.data;
};

export const getProjectExtraComponent = async (guid: string, projectExtraComponentID: number) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/ProjectExtraComponent/' + guid + '/' + projectExtraComponentID, config);
  return response.data;
};

export const updateProjectExtraComponent = async (guid: string, projectExtraComponent: IProjectExtraComponent) => {
  const config = await bearerTokenHeader();
  const response = await axios.post('api/ProjectExtraComponent/' + guid, projectExtraComponent, config);
  return response.data;
};
