import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './commonState';

//  functions

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    userChanged(state, action) {
      // ✅ This "mutating" code is okay inside of createSlice!
      if (!action.payload) {
        state.user = undefined;
      } else {
        state.user = {
          contactName: action.payload.contactName,
          userEmail: action.payload.userEmail,
          userID: action.payload.userID,
          language: action.payload.language,
          roles: action.payload.roles,
          expires_at: action.payload.expires_at,
          expires_in: action.payload.expires_in,
        };
      }
    },
    pageTitleChanged(state, action) {
      // ✅ This "mutating" code is okay inside of createSlice!
      state.pageTitle = action.payload;
    },
    LanguageChanged(state, action) {
      // ✅ This "mutating" code is okay inside of createSlice!
      if (state.user) {
        state.user.language = action.payload;
      }
    },
  },
});

export default commonSlice.reducer;
