export enum PlenumDataSupply {
  supply = 1,
  extraction = 0,
}

export enum PlenumDataInside {
  inside = 1,
  outside = 0,
}

export enum enValveConnectorConnection {
  straight = 4,
  rightAngled = 5,
}
