import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEppColors } from 'application/components/api/EppColor';
import { selectComponentState } from 'redux/store';

export const fetchAsyncEppColors = createAsyncThunk(
  'components/fetchAsyncEppColor',
  async (data: {}, thunkAPI) => {
    const response = await getEppColors();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectComponentState(thunkAPI.getState()).eppColors.loading === 'init';
    },
  }
);
