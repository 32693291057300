import { createAsyncThunk } from '@reduxjs/toolkit';
import { IEppData } from 'application/project/api/EppData';
import {
  getEppDataComponent,
  getEppDataComponents,
  IEppDataComponent,
  updateEppDataComponent,
} from 'application/project/api/EppDataComponent';
import { PlenumDataInside, PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import { selectProjectState } from 'redux/store';

export const fetchAsyncEppDataComponent = createAsyncThunk(
  'project/eppDataComponent/fetchAsyncEppDataComponent',
  async (data: { guid: string; EppDataComponentID: number }, thunkAPI) => {
    const response = await getEppDataComponent(data.guid, data.EppDataComponentID);
    return response;
  }
);

export const fetchAsyncEppDataComponents = createAsyncThunk(
  'project/eppDataComponent/fetchAsyncEppDataComponents',
  async (data: { guid: string }, thunkAPI) => {
    const response = await getEppDataComponents(data.guid);
    return response;
  }
);

export const updateAsyncEppDataComponent = createAsyncThunk(
  'project/eppDataComponent/updateAsyncEppDataComponent',
  async (
    data: { guid: string; supply: PlenumDataSupply; inside: PlenumDataInside; EppDataComponent: IEppDataComponent },
    thunkAPI
  ) => {
    const response = await updateEppDataComponent(data.guid, data.supply, data.inside, data.EppDataComponent);
    return response;
  }
);

export const updateEppDataComponentThunk = (eppData: IEppData, EppDataComponent: IEppDataComponent) => {
  return async (dispatch: any, getState: any) => {
    const projectState = selectProjectState(getState());
    if (projectState.activeProject) {
      await dispatch(
        updateAsyncEppDataComponent({
          guid: projectState.activeProject?.guid,
          supply: eppData.supply,
          inside: eppData.inside,
          EppDataComponent: EppDataComponent,
        })
      );
    }
  };
};
