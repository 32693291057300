import {
  getValveConnectorsEdit,
  IValveConnectorEdit,
} from 'application/editcomp/api/ValveConnectorGrid/ValveConnectorEdit';
import {
  getValveConnectorGridsEdit,
  IValveConnectorGridEdit,
} from 'application/editcomp/api/ValveConnectorGrid/ValveConnectorGridEdit';
import { getValveGridsEdit, IValveGridEdit } from 'application/editcomp/api/ValveConnectorGrid/ValveGridEdit';
import React from 'react';

const useValveConnectorGridData = () => {
  const [valveConnectors, setValveConnectors] = React.useState<IValveConnectorEdit[]>([]);
  const [valveConnectorGrids, setValveConnectorGrids] = React.useState<IValveConnectorGridEdit[]>([]);
  const [valveGrids, setValveGrids] = React.useState<IValveGridEdit[]>([]);

  const valveConnectorGridUpdated = React.useCallback(
    (ValveConnectorGridEdit: IValveConnectorGridEdit) => {
      setValveConnectorGrids((items: IValveConnectorGridEdit[]) =>
        items.map((item) => {
          if (item.valveConnectorGridID === ValveConnectorGridEdit.valveConnectorGridID) {
            return ValveConnectorGridEdit;
          } else {
            return item;
          }
        })
      );
    },
    [setValveConnectorGrids]
  );

  React.useEffect(() => {
    getValveConnectorGridsEdit().then((result) => setValveConnectorGrids(result));
    getValveConnectorsEdit().then((result) => setValveConnectors(result));
    getValveGridsEdit().then((result) => setValveGrids(result));
  }, []);

  return React.useMemo(
    () => ({
      valveConnectors: valveConnectors,
      valveConnectorGrids: valveConnectorGrids,
      valveGrids: valveGrids,
      valveConnectorGridUpdated: valveConnectorGridUpdated,
    }),
    [valveConnectors, valveConnectorGrids, valveGrids, valveConnectorGridUpdated]
  );
};

export default useValveConnectorGridData;
