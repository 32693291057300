import { makeStyles, createTheme, createStyles, Theme } from '@material-ui/core/styles';

export const useGlobalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      padding: theme.spacing(2),
      width: '100%',
      marginTop: theme.spacing(2),
    },
    regularContentWidth: {
      maxWidth: 940,
    },
    regularContent: {
      maxWidth: 940,
      padding: theme.spacing(2),
    },
    roomContent: {
      padding: theme.spacing(2),
    },
  })
);

export const globalTheme = createTheme({
  palette: {
    primary: {
      main: '#006699',
    },
    secondary: {
      main: '#ee7203',
    },
    text: {
      primary: '#666666',
    },
    divider: '#006699',
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 14,
    fontFamily: 'Open Sans, sans-serif',

    h6: {
      fontWeight: 600,
    },
  },
});
