import { Button } from '@material-ui/core';
import {
  getInstallationCalcultionHtml,
  getInstallationPlanHtml,
  getInstallationPlanPdf,
} from 'application/project/api/InstallationPlan';
import { selectActiveProject } from 'application/project/redux/selectors/projectSelector';
import EditorPage from 'components/controls/EditorPage';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const InstallationPlan = (props: { calculation?: boolean; onNext: () => any }) => {
  const project = useSelector(selectActiveProject);

  const [htmlString, setHtmlString] = React.useState('');
  const [downLoading, setDownLoading] = React.useState(false);
  const { t } = useTranslation(['installationplan']);

  React.useEffect(() => {
    if (project) {
      if (props.calculation === true) {
        getInstallationCalcultionHtml(project.guid).then((result) => {
          setHtmlString(result);
        });
      } else {
        getInstallationPlanHtml(project.guid).then((result) => {
          setHtmlString(result);
        });
      }
    }
  }, [project, props.calculation]);

  return (
    <EditorPage title={t('title', 'installation plan')} onNext={undefined /*props.onNext*/}>
      <div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={downLoading}
          onClick={React.useCallback(() => {
            if (project) {
              setDownLoading(true);
              getInstallationPlanPdf(project.guid).then((result) => {
                setDownLoading(false);
              });
            }
          }, [project])}>
          {downLoading ? t('downloading', 'downloading ...') : t('downloadpdf', 'download pdf')}
        </Button>
      </div>
      <div dangerouslySetInnerHTML={{ __html: htmlString }} />
    </EditorPage>
  );
};

export default InstallationPlan;
