import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant';
import { IValidateResult, validateNoError } from './TextInputField';

export default function DateInputField(props: {
  value: string | undefined;
  name?: string;
  label?: string;
  helperText?: string;
  onChange: (value: string | undefined, name: string, error: string) => void;
  validate?: (value: string | undefined) => IValidateResult;
  onBlur?: (value: string | undefined) => string;
  required?: boolean;
  debounce?: boolean;
  disabled?: boolean;
  style?: any;
  readOnly?: boolean;
  fullWidth?: boolean;
  className?: string;
  error?: boolean;
}) {
  //const classes = useStyles();

  const { onChange, validate } = props;

  const valueRef = React.useRef<string>('');

  const date2string = (date: string | undefined) =>
    date && date.length > 0 ? new Date(date.substr(0, 10)).toISOString().substr(0, 10) : '';
  const valuestring = date2string(props.value);
  if (valueRef.current !== valuestring) {
    valueRef.current = valuestring;
  }

  const [TextValue, setTextValue] = React.useState<string>(valuestring);
  //const [Error, setError] = React.useState<IValidateResult>(
  //  props.validate ? props.validate(props.value ? props.value.toString() : '') : validateNoError()
  //);

  const onChangeCallBack = React.useCallback(
    (value: string | undefined, name: string, error: string) => {
      if (valueRef.current !== value) {
        onChange(value, name, error);
      }
    },
    [onChange]
  );

  // Debounce the original search async function
  const searchAPIDebounced = useConstant(() => AwesomeDebouncePromise(onChangeCallBack, 1000));

  React.useEffect(() => {
    setTextValue(date2string(props.value));
  }, [props.value]);

  const handleTextChange = React.useCallback(
    async (value: string | undefined, name: string) => {
      setTextValue(value || '');
      let error = validate ? validate(value) : validateNoError();
      if (props.debounce) {
        await searchAPIDebounced(value, name, error.error);
      } else {
        if (props.value !== value) {
          onChangeCallBack(value, name, error.error);
        }
      }
    },
    [onChangeCallBack, validate, props.debounce, searchAPIDebounced, props.value]
  );

  return (
    <TextField
      name={props.name}
      label={props.label}
      helperText={props.helperText}
      type="date"
      variant="filled"
      fullWidth={props.fullWidth}
      value={TextValue}
      className={props.className}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
          handleTextChange(event.target.value, event.target.name);
        },
        [handleTextChange]
      )}
    />
  );
}
