import Button from '@material-ui/core/Button';
import * as React from 'react';
import EditProject from './EditProject';
import ProjectItem from './ProjectItem';
import useEditProject from './useEditProject';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Link, useHistory } from 'react-router-dom';
import useEditDialog from 'components/dialog/useEditDialog';
import { getProjectObject, getProjects, IProject } from 'application/project/api/Project';
import EditDialog from 'components/dialog/EditDialog';
import { Container } from 'reactstrap';
import TextInputField from 'components/controls/TextInputField';
import useConfirmDialog from 'components/dialog/useConfirmDialog';
import ConfirmDialog from 'components/dialog/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { asyncSetActiveProject } from 'application/project/redux/thunks/AsyncProject';
import { commonSlice } from 'application/common/redux/commonSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    addButton: {
      height: 53,
      float: 'left',
      width: 150,
    },
    flexContainer: {
      display: 'flex',
    },

    flexGrowHor: {
      flexGrow: 1,
    },
  })
);

export const ProjectList = (props: { preFlight: boolean }) => {
  const [editDialogState, showEditDialog] = useEditDialog();
  const [findProject, setFindProject] = React.useState('');
  const [projects, setProjects] = React.useState<IProject[]>([]);
  const [conformDialog, setConfirm] = useConfirmDialog();
  const history = useHistory();
  const { t } = useTranslation(['projectlist', 'common']);
  const styles = useStyles();
  const dispatch = useDispatch();

  const { preFlight } = props;

  React.useEffect(() => {
    const title = t('title', 'Project list') + ' - Hybalans+';
    document.title = title;
    dispatch(commonSlice.actions.pageTitleChanged(title));
  }, [t, dispatch]);

  const [project, editProjectState] = useEditProject(
    (editproject) => {
      //update project list
      setProjects((prevProjects: IProject[]) => {
        const prevProject = prevProjects.find((a) => a.guid === editproject.guid);
        if (prevProject) {
          return prevProjects.map((pre) => {
            if (pre.guid === editproject.guid) {
              return editproject;
            } else {
              return pre;
            }
          });
        } else {
          return [editproject, ...prevProjects];
        }
      });
      //active project
      const url = '/project/setactive/' + editproject.guid;
      history.push(url);
    },
    (deleteProject) =>
      setProjects((prevProjects: IProject[]) => prevProjects.filter((a: IProject) => a.guid !== deleteProject.guid))
  );

  React.useEffect(() => {
    if (!preFlight) {
      getProjects().then((projects) => {
        if (projects) {
          setProjects(projects);
        }
      });
    }
  }, [preFlight]);

  const filteredProjects = React.useMemo(
    () =>
      projects
        .filter(
          (a) =>
            a.title.toLowerCase().includes(findProject.toLowerCase()) ||
            a.address.toLowerCase().includes(findProject.toLowerCase()) ||
            a.description.toLowerCase().includes(findProject.toLowerCase())
        )
        .sort((a, b) => b.date.localeCompare(a.date)),

    [findProject, projects]
  );

  const editProject = React.useCallback(
    (editproject?: IProject) => {
      editProjectState.setProject(editproject ? editproject : getProjectObject());
      showEditDialog({
        title: t('projectlist:edit_title', 'Edit common project settings'),
        onApply: editProjectState.handleSubmitEdit,
        canApply: editProjectState.canSubmit,
      });
    },
    [editProjectState, showEditDialog, t]
  );

  const copyProject = React.useCallback(
    (editproject: IProject) => {
      editProjectState.setProject(editproject);
      showEditDialog({
        title: t('projectlist:copy_title', 'Copy project'),
        onApply: editProjectState.handleSubmitCopy,
        canApply: editProjectState.canSubmit,
      });
    },
    [editProjectState, showEditDialog, t]
  );

  const deleteProject = React.useCallback(
    (editproject: IProject) => {
      editProjectState.setProject(editproject);
      setConfirm({
        title: t('projectlist:delete_title', 'delete project'),
        question: t('projectlist:delete_question', 'delete project {{title}}, date:{{date, shortdate}}?', {
          title: editproject.title,
          date: new Date(editproject.date),
        }),
        onConfirm: editProjectState.handleSubmitDelete,
      });
    },
    [editProjectState, setConfirm, t]
  );

  return (
    <div className="container">
      <main>
        <Container>
          <div>
            <ConfirmDialog {...conformDialog} />
            <EditDialog {...editDialogState}>
              <EditProject project={project} {...editProjectState} />
            </EditDialog>
            <div className={styles.flexContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => editProject(undefined)}
                className={styles.addButton}>
                <AddCircleIcon />
              </Button>
              <TextInputField
                style={{ marginLeft: 16 }}
                label={t('projectlist:main_find', 'find project')}
                name="find"
                className={styles.flexGrowHor}
                debounce={true}
                value={findProject}
                onChange={React.useCallback((value: string, name: string, error: string) => setFindProject(value), [])}
              />
            </div>
            {filteredProjects.map((project: IProject) => (
              <Link
                to={'/project/setactive/' + project.guid}
                key={project.guid}
                className={styles.link}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(asyncSetActiveProject(project));
                  history.push('/project/edit');
                }}>
                <ProjectItem
                  project={project}
                  editProject={editProject}
                  copyProject={copyProject}
                  deleteProject={deleteProject}
                />
              </Link>
            ))}
          </div>
        </Container>
      </main>
    </div>
  );
};

export default ProjectList;
