import { projectSlice } from '../projectSlice';
import { updateConnectionThunk } from './AsyncConnectionUpdate';

export const ValveConnectionSetValue = (
  valveConnectionKey: string,
  name: string,
  value: string | number | boolean | undefined
) => {
  return async (dispatch: any, getState: any) => {
    await dispatch(projectSlice.actions.updateValveConnection({ id: valveConnectionKey, name: name, value: value }));
    await dispatch(updateConnectionThunk(valveConnectionKey));
  };
};
