import { IPlenumConnection, IValveConnection } from 'application/project/api/Connection';
import { IPlenumData } from 'application/project/api/PlenumData';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import NumberSelectRestrictor from './controls/NumberSelectRestrictor';
import { SetPlenumConnectionValue } from 'application/project/redux/thunks/SetPlenumConnectionValue';
import { plenumConnectionKey } from 'application/project/redux/adapters/plenumConnectionAdapter';

const PartPlenumConnection = (props: {
  plenumdata: IPlenumData;
  valveConnection: IValveConnection;
  plenumConnection: IPlenumConnection;
}) => {
  const { plenumConnection } = props;
  const dispatch = useDispatch();

  const onChange = React.useCallback(
    (value: number | string | boolean | undefined, field: string, error: string) =>
      dispatch(
        SetPlenumConnectionValue(plenumConnection.valveConnectionKey, plenumConnectionKey(plenumConnection), {
          [field]: value,
        })
      ),
    [dispatch, plenumConnection]
  );

  return (
    <div>
      <NumberSelectRestrictor
        plenumdata={props.plenumdata}
        valveConnection={props.valveConnection}
        plenumConnection={props.plenumConnection}
        onChange={onChange}
      />
    </div>
  );
};

export default PartPlenumConnection;
