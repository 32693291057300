import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IEppColor {
  eppColorID: number;
  name: string;
  colorValue: number;
}

export const getEppColors = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/EppColor', config);
  return response.data;
};
