import * as React from 'react';
import { ISection, ISectionBend } from 'application/project/api/Connection';
import nameof from 'ts-nameof.macro';
import { useDispatch, useSelector } from 'react-redux';
import { IBend } from 'application/components/api/Bend';
import AmountInputField from 'components/controls/AmountInputField';
import { SetSectionBendValue } from 'application/project/redux/thunks/SetBendBendValue';
import { selectSectionBendByKey } from 'application/project/redux/selectors/sectionBendSelectors';
import { sectionBendKeyIntern } from 'application/project/redux/adapters/sectionBendAdapter';

const PartDuctElbow = (props: { bend: IBend; section: ISection }) => {
  const dispatch = useDispatch();
  const { section, bend } = props;

  const sectionBend = useSelector((state) =>
    selectSectionBendByKey(
      state,
      sectionBendKeyIntern(section.valveConnectionKey, section.plenumConnectionIndex, section.indexNumber, bend.bendID)
    )
  );

  const onChange = React.useCallback(
    (value: number | undefined, field: string, error: string) =>
      dispatch(SetSectionBendValue(section.valveConnectionKey, section, bend.bendID, value)),
    [dispatch, section]
  );

  return (
    <AmountInputField
      label={bend.name}
      name={nameof<ISectionBend>((a) => a.amount)}
      value={sectionBend?.amount}
      onChange={onChange}
      fullWidth
    />
  );
};

export default PartDuctElbow;
