import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

export const useActionButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      marginBottom: 21.74,
      maxWidth: '100%',
    },
    link: {
      color: '#ffffff !important',
      display: 'inline-block',
      paddingLeft: 20,
      paddingRight: 48,
      paddingTop: 14,
      paddingBottom: 14,
      backgroundColor: theme.palette.secondary.main,
      fontWeight: 600,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'transparent',
      textTransform: 'uppercase',
      '&::after': {
        content: '"" !important',
      },
      '&:hover': {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main + ' !important',
        backgroundColor: '#ffffff',
        textDecoration: 'none',
      },
      position: 'relative',
    },
    helperlink: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main + ' !important',
        backgroundColor: '#ffffff',
        textDecoration: 'none',
      },
    },
    arrow: {
      fontWeight: 400,
      fontSize: 20,
      height: 20,
      lineHeight: 20,
      marginTop: 'auto',
      marginBottom: 'auto',
      fontFamily: 'FontAwesome',
      position: 'absolute',
      right: 19,
      textAlign: 'center',
    },
  })
);

const ActionButton = (props: {
  linkClassName?: string;
  className?: string;
  style?: any;
  caption: any;
  helperlink?: boolean;
  href?: string;
  onClick?: () => any;
  title: string;
}) => {
  const classes = useActionButtonStyles();

  const onClick = (e: any) => {
    e.preventDefault();
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div className={clsx(classes.root, props.className)} style={props.style}>
      <a
        href={props.href}
        onClick={props.onClick !== undefined ? onClick : undefined}
        title={props.title}
        className={clsx(classes.link, props.linkClassName, props.helperlink ? classes.helperlink : null)}>
        {props.caption}
        <FontAwesomeIcon className={classes.arrow} icon={faAngleRight} />
      </a>
    </div>
  );
};

export default ActionButton;
