import { createSelector } from '@reduxjs/toolkit';
import { PlenumDataInside, PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import { selectProjectState } from 'redux/store';
import { eppDataComponentAdapter } from '../adapters/eppDataComponentAdapter';

export const {
  selectAll: selectAllEppDataComponents,
  selectById: selectEppDataComponentById,
  selectEntities: selectEppDataComponentEntities,
} = eppDataComponentAdapter.getSelectors((state) => selectProjectState(state).eppdatacomponents);

export const makeSelectEppDataComponentsByDirection = () => {
  return createSelector(
    (state: any, supply: PlenumDataSupply, inside: PlenumDataInside) => supply,
    (state: any, supply: PlenumDataSupply, inside: PlenumDataInside) => inside,
    // First, pass one or more "input selector" functions:
    selectAllEppDataComponents,
    // Then, an "output selector" that receives all the input results as arguments
    // and returns a final result value
    (supply, inside, eppdatacomponents) =>
      eppdatacomponents.filter(
        (eppdatacomponent) => eppdatacomponent.supply === supply && eppdatacomponent.inside === inside
      )
  );
};
