import { RadioSelector } from 'components/controls/RadioSelector';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const ValveDiameterSelector = (props: {
  value: number | undefined;
  name: string;
  onChange: (value: number | undefined, name: string, error: string) => any;
}) => {
  const { t } = useTranslation(['project']);
  const options = React.useMemo(
    () => [
      { id: 1, name: t('valvediameter117', '117 mm') },
      { id: 2, name: t('valvediameter125', '125 mm') },
    ],
    [t]
  );

  return (
    <RadioSelector
      label={t('valvediameter', 'valve diameter')}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      valueFieldName="id"
      displayFieldName="name"
      options={options}
    />
  );
};
