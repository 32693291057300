import { ISettings } from 'application/editcomp/api/Settings';
import { IGridStatePlaceHolder } from '../../controls/hooks/useGridStateData';
import { IGridStateData } from '../Settings';

export interface IRenderRowCallBacks {
  name: (setting: ISettings) => any;
  value: (setting: ISettings) => any;
  encrypted: (setting: ISettings) => any;
}

export const RenderRow = (
  state: IGridStatePlaceHolder<IGridStateData>,
  rowCount: number,
  callBacks: IRenderRowCallBacks,
  setting: ISettings | undefined
) => {
  let result: any[] = [];
  result.push(callBacks.name(setting || ({} as ISettings)));
  result.push(callBacks.value(setting || ({} as ISettings)));
  result.push(callBacks.encrypted(setting || ({} as ISettings)));
  return result;
};
