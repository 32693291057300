import { IEppData } from 'application/project/api/EppData';
import NumberInputField from 'components/controls/NumberInputField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import nameof from 'ts-nameof.macro';

export const ExtraResistant = (props: {
  eppData: IEppData;
  onChange: (value: string | number | undefined | boolean, field: string, error: string) => any;
}) => {
  const { t } = useTranslation(['eppedit']);
  const helperText = React.useMemo(
    () => t('additionalpressurelosshelper', 'Additional pressure loss (external sound damper, fire damper)'),
    [t]
  );

  return (
    <NumberInputField
      label={t('additionalpressureloss', 'Additional pressure loss (Pa)')}
      value={props.eppData.otherResistance}
      onChange={props.onChange}
      helperText={helperText}
      debounce
      type="decimal"
      name={nameof(props.eppData.otherResistance)}
    />
  );
};

export default ExtraResistant;
