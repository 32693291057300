import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getProjectExtraComponent,
  getProjectExtraComponents,
  IProjectExtraComponent,
  updateProjectExtraComponent,
} from 'application/project/api/ProjectExtraComponent';
import { selectProjectState } from 'redux/store';

export const fetchAsyncProjectExtraComponent = createAsyncThunk(
  'project/extraComponent/fetchAsyncProjectExtraComponent',
  async (data: { guid: string; projectExtraComponentID: number }, thunkAPI) => {
    const response = await getProjectExtraComponent(data.guid, data.projectExtraComponentID);
    return response;
  }
);

export const fetchAsyncProjectExtraComponents = createAsyncThunk(
  'project/extraComponent/fetchAsyncProjectExtraComponents',
  async (data: { guid: string }, thunkAPI) => {
    const response = await getProjectExtraComponents(data.guid);
    return response;
  }
);

export const updateAsyncProjectExtraComponent = createAsyncThunk(
  'project/extraComponent/updateAsyncProjectExtraComponent',
  async (
    data: {
      guid: string;
      projectExtraComponent: IProjectExtraComponent;
    },
    thunkAPI
  ) => {
    const response = await updateProjectExtraComponent(data.guid, data.projectExtraComponent);
    return response;
  }
);

export const updateProjectExtraComponentThunk = (projectExtraComponent: IProjectExtraComponent) => {
  return async (dispatch: any, getState: any) => {
    const projectState = selectProjectState(getState());
    if (projectState.activeProject) {
      await dispatch(
        updateAsyncProjectExtraComponent({
          guid: projectState.activeProject?.guid,
          projectExtraComponent: projectExtraComponent,
        })
      );
    }
  };
};
