import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface ISettings {
  settingID: string;
  value: string | null;
  encrypted: boolean;
}

export const getSettingsEdit = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/Settings/edit', config);
  return response.data;
};

export const updateSettingsEdit = async (setting: ISettings) => {
  const config = await bearerTokenHeader();
  const response = await axios.post('api/Settings/edit', setting, config);
  return response.data;
};
