import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';
import nameof from 'ts-nameof.macro';

export enum enFlowUnit {
  m3_h = 1,
  dm3_s = 2,
}

export interface IProject {
  guid: string;
  title: string;
  description: string;
  address: string;
  advizer: string;
  date: string;
  ductTypeID: number;
  ductType: string;
  ventilationTypeID: number;
  ventilationType: string;
  heatRecoveryUnitID?: number;
  heatRecoveryUnit?: string;
  valveDiameterID: number;
  valveDiameter: string;
  flowUnitID: enFlowUnit;
  experimental: boolean;
  attributes: string[];
}

export const getProjectObject = (project?: IProject): IProject => {
  if (project) {
    return { ...project };
  } else {
    return {
      guid: '',
      title: '',
      description: '',
      address: '',
      advizer: '',
      date: new Date().toISOString(),
      ductTypeID: 1,
      ductType: '',
      ventilationTypeID: 1,
      ventilationType: '',
      valveDiameterID: 0,
      valveDiameter: '',
      flowUnitID: enFlowUnit.dm3_s,
      experimental: false,
      attributes: [],
    };
  }
};

const mapProject = (project: any): IProject => {
  return {
    ...project,
    experimental: project.attributes?.includes(nameof<IProject>((a) => a.experimental)) || false,
  };
};

export const getProjects = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/project', config);
  if (!response) return undefined;
  return response.data.map((project: any) => mapProject(project));
};

export const getProject = async (guid: string) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/project/' + guid, config);
  return mapProject(response.data);
};

export const createProject = async (project: IProject) => {
  const config = await bearerTokenHeader();
  const response = await axios.post('api/project', project, config);
  return mapProject(response.data);
};

export const copyProject = async (project: IProject) => {
  const config = await bearerTokenHeader();
  const response = await axios.post('api/project/' + project.guid, project, config);
  return mapProject(response.data);
};

const addRemoveAttribute = (attributes: string[], name: string, value: boolean) => {
  let newAttributes = [...attributes];
  const index = newAttributes.indexOf(name);
  if (value && index === -1) newAttributes.push(name);
  else if (!value && index >= 0) newAttributes = newAttributes.filter((a) => a === name);
  return newAttributes;
};

export const updateProject = async (project: IProject) => {
  const config = await bearerTokenHeader();
  let attributes = addRemoveAttribute(project.attributes, nameof(project.experimental), project.experimental);
  const response = await axios.put('api/project/' + project.guid, { ...project, attributes: attributes }, config);
  return mapProject(response.data);
};

export const deleteProject = async (project: IProject) => {
  const config = await bearerTokenHeader();
  const response = await axios.delete('api/project/' + project.guid, config);
  return mapProject(response.data);
};
