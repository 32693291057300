import { getSettingsEdit, ISettings, updateSettingsEdit } from 'application/editcomp/api/Settings';
import React from 'react';

const newSetting = () => ({
  settingID: '',
  value: '',
  encrypted: false,
});

const useSettingsGridData = () => {
  const [settings, setSettings] = React.useState<ISettings[]>([]);
  const [setting, setSetting] = React.useState<ISettings>(newSetting);
  const savingRef = React.useRef(false);

  React.useEffect(() => {
    getSettingsEdit().then((result) => setSettings(result));
  }, []);

  const saveSetting = React.useCallback(
    (setting: ISettings) => {
      if (savingRef.current === true) return;
      savingRef.current = true;
      updateSettingsEdit(setting).then((result) => {
        savingRef.current = false;
        setSettings((prev) => {
          if (prev.find((a) => a.settingID === result.settingID)) {
            return prev.map((a) => (a.settingID === result.settingID ? result : a));
          } else {
            setSetting(newSetting);
            return [...prev].concat(result);
          }
        });
      });
    },
    [setSettings, setSetting, setting]
  );

  return React.useMemo(
    () => ({
      settings: settings,
      setting: setting,
      saveSetting: saveSetting,
      setSetting: setSetting,
    }),
    [settings, saveSetting, setting, setSetting]
  );
};

export default useSettingsGridData;
