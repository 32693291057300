import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IProjectArticle {
  projectArticleID: number;
  articleNumber: string;
  name: string;
  amount: number;
  quantity: number;
  quantityPerItem: number;
}

export const getProjectArticles = async (guid: string) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/ProjectArticle/' + guid, config);
  return response.data;
};
