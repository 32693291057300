import * as React from 'react';
import { tableStyles } from './tableStyles';
import clsx from 'clsx';

interface ITdProps {
  className?: string;
  children?: any;
  colSpan?: number;
  rowSpan?: number;
  title?: string;
  onClick?: any;
  onMouseEnter?: any;
  firstRow?: boolean;
  firstCell?: boolean;
  style?: any;
  stickyRowTop?: number;
  stickyColumnLeft?: number;
  borderLeft?: string;
  borderRight?: string;
  borderBottom?: string;
  borderTop?: string;
  totalCell?: boolean;
  textAlign?: 'left' | 'center' | 'right';
}

const Td = (props: ITdProps) => {
  const tableClasses = tableStyles();

  let borderLeft = props.borderLeft;
  let borderRight = props.borderRight;
  let borderBottom = props.borderBottom;
  let borderTop = props.borderTop;
  //standard borders ...
  //firstRow props.borderTop default no border ...
  if (borderLeft === undefined) {
    if (props.firstCell) {
      borderLeft = tableClasses.BorderLeft;
    }
  }
  if (borderRight === undefined) borderRight = tableClasses.BorderRight;
  if (borderBottom === undefined) borderBottom = tableClasses.BorderBottom;

  const style: any = { ...props.style };

  if (props.textAlign) {
    style.textAlign = props.textAlign;
  }

  if (props.totalCell === true) {
    style.fontWeight = 'bold';
  }

  if (props.stickyRowTop !== undefined) {
    style.top = props.stickyRowTop;
    style.position = 'sticky';
    style.zIndex = 5;
  }

  if (props.stickyColumnLeft !== undefined) {
    style.left = props.stickyColumnLeft;
    style.position = 'sticky';
    style.zIndex = 4;
  }

  if (props.stickyRowTop !== undefined && props.stickyColumnLeft !== undefined) {
    style.zIndex = 6;
  }

  let newProps: any = { ...props };
  delete newProps.borderTop;
  delete newProps.borderLeft;
  delete newProps.borderBottom;
  delete newProps.borderRight;
  delete newProps.stickyRowTop;
  delete newProps.stickyColumnLeft;
  delete newProps.firstRow;
  delete newProps.firstCell;
  delete newProps.textAlign;
  delete newProps.totalCell;

  return (
    <td
      {...newProps}
      style={style}
      className={clsx(
        tableClasses.tableBorder,
        tableClasses.cellPadding,
        borderTop,
        borderLeft,
        borderBottom,
        borderRight,
        props.className,
        props.firstRow ? tableClasses.BorderTop : null,
        props.stickyColumnLeft !== undefined ? tableClasses.stickyRowColor : null
      )}
      onClick={props.onClick}>
      {props.children}
    </td>
  );
};

export default Td;
