import { IApiValveConnection } from 'application/project/api/Connection';
import { selectProjectState } from 'redux/store';
import { valveConnectionAdapter } from '../adapters/valveConnectionAdapter';
import { projectSlice } from '../projectSlice';
import { updateAsyncConnection } from './AsyncConnectionUpdate';

export const ValveConnectionDelete = (valveConnectionKey: string) => {
  return async (dispatch: any, getState: any) => {
    const projectState = selectProjectState(getState());
    const connection = valveConnectionAdapter
      .getSelectors()
      .selectById(projectState.valveConnections, valveConnectionKey);
    if (!projectState.activeProject) return;
    if (!connection) return;

    const apiConnection: IApiValveConnection = {
      ...connection,
      remove: true,
      plenumConnections: [],
    };

    await dispatch(projectSlice.actions.deleteValveConnection({ id: valveConnectionKey }));
    await dispatch(
      updateAsyncConnection({
        guid: projectState.activeProject?.guid,
        apiConnection: apiConnection,
      })
    );
  };
};
