import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IValveConnectorGrid {
  valveConnectorGridID: number;
  name: string;
  valveConnectorID: number;
  valveGridID: number;
  imageID: number;
  valveGridImageID: number;

  supplyFlowMax: number;
  extractionFlowMax: number;
  supplyFlowMaxDuplex: number;
  extractionFlowMaxDuplex: number;

  supplyFlowWarn: number;
  extractionFlowWarn: number;
  supplyFlowWarnDuplex: number;
  extractionFlowWarnDuplex: number;

  canSupply: boolean;
  canExtraction: boolean;
  canDuplex: boolean;

  onlyWall: boolean;
  isCustom: boolean;
  valveConnectionID: number;
  valveDiameterID: number;
  plenumConnector: boolean;
  ductID: number;
}

export const getValveConnectorGrids = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/ValveConnectorGrid', config);
  return response.data;
};

export const uploadValveConnectorGridImage = async (valveConnectorGridID: number, file: any, name: string) => {
  // Create an object of formData
  const formData = new FormData();

  // Update the formData object
  formData.append('files', file, name);
  const response = await axios.post('api/ValveConnectorGrid/image/' + valveConnectorGridID, formData).then();
  return response.data;
};

export const getValveConnectorGridImage = (valveConnectorGridID: number, imageID: number) => {
  return imageID ? 'api/ValveConnectorGrid/image/' + valveConnectorGridID + '/' + imageID : '';
};
