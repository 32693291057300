const DecimalSeparator = (1.1).toLocaleString().substring(1, 2);

export function FormatDecimal(value: number | undefined, decimals: number = 2): string {
  let power = 10 ** decimals;
  if (value === undefined || value === null || isNaN(value)) {
    return '';
  }
  return (Math.round(value * power) / power).toFixed(decimals).replace('.', DecimalSeparator);
}

export function FormatInteger(value: number | undefined): string {
  if (value && !isNaN(value) && Math.round(value) !== 0) {
    return Math.round(value).toLocaleString();
  } else {
    return '\u00A0';
  }
}

export function FormatPercentage(value: number | undefined, decimals: number = 1): string {
  let power = 10 ** decimals;
  if (value === undefined || value === null || isNaN(value)) {
    return '';
  }
  return (Math.round(value * 100 * power) / power).toFixed(decimals).replace('.', DecimalSeparator);
}

export function FormatMin2HourMin(value?: number): string {
  if (value && !isNaN(value) && Math.round(value) !== 0) {
    const hours = Math.floor(value / 60);
    const min = value - 60 * hours;
    const szMin = '0' + min.toLocaleString();
    return hours.toLocaleString() + ':' + szMin.substr(szMin.length - 2);
  } else {
    return '\u00A0';
  }
}

export function ParseDecimal(value: string | undefined): number | undefined {
  if (value === undefined || value === null || value === '') {
    return undefined;
  }
  if (typeof value === 'number') return value;
  const val = parseFloat(value.replace(DecimalSeparator, '.'));
  return isNaN(val) ? undefined : val;
}

export function ParseInteger(value: string | undefined): number | undefined {
  if (value === undefined || value === null || value === '') {
    return undefined;
  }
  if (typeof value === 'number') return value;
  const val = parseInt(value);
  return isNaN(val) ? undefined : val;
}
