import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnections } from 'application/project/api/Connection';

export const fetchAsyncConnections = createAsyncThunk(
  'project/connection/fetchAsyncConnections',
  async (data: { guid: string }, thunkAPI) => {
    const response = await getConnections(data.guid);
    return response;
  }
);
