import MenuItem from '@material-ui/core/MenuItem';
import { ductAdapter } from 'application/components/redux/adapters/ductAdapter';
import { selectAllDucts } from 'application/components/redux/selectors/ductSelector';
import { selectPlenumById } from 'application/components/redux/selectors/plenumSelector';
import {
  IValveConnection,
  IPlenumConnection,
  SECTIONS_AS_PLENUMCONNECTION_0,
} from 'application/project/api/Connection';
import { IPlenumData } from 'application/project/api/PlenumData';
import { sectionKeyIntern } from 'application/project/redux/adapters/sectionAdapter';
import { selectValveConnectionEntities } from 'application/project/redux/selectors/connectionSelectors';
import { makeSelectPlenumConnectionsByDirection } from 'application/project/redux/selectors/plenumConnectionSelectors';
import { selectSectionByKey } from 'application/project/redux/selectors/sectionSelectors';
import clsx from 'clsx';
import { CheckBoxer } from 'components/controls/CheckBoxer';
import Selector from 'components/controls/Selector';
import TextInputField from 'components/controls/TextInputField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectComponentState } from 'redux/store';
import nameof from 'ts-nameof.macro';
import connectionStyles from './connectionStyles';

const NumberSelectRestrictor = (props: {
  plenumdata: IPlenumData;
  valveConnection: IValveConnection;
  plenumConnection: IPlenumConnection;
  onChange: (value: number | boolean | undefined, field: string, error: string, plenumConnectionIndex: number) => any;
}) => {
  const classes = connectionStyles();
  const { onChange } = props;
  const { t } = useTranslation(['roomeditor']);

  const reduxDucts = useSelector((state) => selectComponentState(state).ducts);
  /*
  wat hebben we nodig?
  - aantal aansluitingen (plenum)
  - welke aansluitingen zijn bezet
  - welke aansluiting is van ons
  */
  const firstSection = useSelector((state) =>
    selectSectionByKey(
      state,
      sectionKeyIntern(props.plenumConnection.valveConnectionKey, SECTIONS_AS_PLENUMCONNECTION_0, 0)
    )
  );

  const plenum = useSelector((state) =>
    selectPlenumById(state, props.plenumdata.plenumID ? props.plenumdata.plenumID : 0)
  );

  const selectPlenumConnectionsByDirection = React.useMemo(makeSelectPlenumConnectionsByDirection, []);
  const plenumConnections = useSelector((state) => selectPlenumConnectionsByDirection(state, props.plenumdata.supply));

  const valveConnectionEntities = useSelector(selectValveConnectionEntities);

  const canRightAngled = React.useMemo(
    () => ductAdapter.getSelectors().selectById(reduxDucts, firstSection?.ductID || 0)?.canRightAngled || false,
    [firstSection, reduxDucts]
  );

  const onThisChanged = React.useCallback(
    (value: number | boolean | undefined, field: string, error: string) => {
      onChange(value, field, error, props.plenumConnection.indexNumber);
    },
    [onChange, props.plenumConnection.indexNumber]
  );

  const options = React.useMemo(() => {
    const optionsResult: {
      index: number;
      description: string;
      disabled: boolean;
    }[] = [];
    if (plenum) {
      for (let i = 1; i <= plenum?.numberConnections; i++) {
        const connection = plenumConnections.find((a) => a.connectionIndex === i);

        let used = connection !== undefined;

        if (
          used &&
          connection?.valveConnectionKey === props.valveConnection.valveConnectionKey &&
          props.plenumConnection.connectionIndex === i
        ) {
          //is deze door onszelf in gebruikt
          used = false;
        }

        optionsResult.push({
          index: i,
          description:
            i.toString() +
            ' ' +
            (connection !== undefined ? valveConnectionEntities[connection?.valveConnectionKey]?.description : ''),
          disabled: used,
        });
      }
    }
    return optionsResult;
  }, [
    plenumConnections,
    valveConnectionEntities,
    plenum,
    props.valveConnection.valveConnectionKey,
    props.plenumConnection?.connectionIndex,
  ]);

  return (
    <div className={clsx(classes.partComplexBlock)}>
      <div className={clsx(classes.halfLeft)}>
        <Selector
          fullWidth
          required={true}
          label={t('connectionnumber', 'connectionnumber')}
          helperText={t('connectionnumberHelper', 'select connection')}
          name={nameof<IPlenumConnection>((o) => o.connectionIndex)}
          value={props.plenumConnection.connectionIndex}
          onChange={onThisChanged}
          items={options.map((option) => (
            <MenuItem key={option.index} value={option.index.toString()}>
              {option.description}
            </MenuItem>
          ))}
        />
      </div>
      <div className={clsx(classes.halfRight)}>
        <div className={classes.flexContainer}>
          {canRightAngled ? (
            <CheckBoxer
              value={props.plenumConnection.connectorRightAngled}
              name={nameof<IPlenumConnection>((o) => o.connectorRightAngled)}
              label={t('plenumElbow', 'elbow')}
              onChange={onThisChanged}
              className={classes.elbow}
            />
          ) : null}

          <TextInputField
            fullWidth
            label={t('restrictor', 'flow restrictor')}
            name=""
            className={clsx(classes.flexGrowHor, 'restrictor')}
            readOnly={true}
            value={(props.plenumConnection.restriction || t('restrictionNone', 'none')) as string}
            onChange={React.useCallback((value: string, name: string, error: string) => {}, [])}
          />
        </div>
      </div>
    </div>
  );
};

export default NumberSelectRestrictor;
