import { valveConnectorGridAdapter } from 'application/components/redux/adapters/valveConnectorGridAdapter';
import { enValveConnectorConnection, PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import { selectComponentState, selectProjectState } from 'redux/store';
import { valveConnectionAdapter } from '../adapters/valveConnectionAdapter';
import { projectSlice } from '../projectSlice';

export const ValveConnectionAdd = (supply: PlenumDataSupply) => {
  return async (dispatch: any, getState: any) => {
    const componentState = selectComponentState(getState());
    const projectState = selectProjectState(getState());
    //get last add connection
    const connections = valveConnectionAdapter
      .getSelectors()
      .selectAll(projectState.valveConnections)
      .filter((a) => a.supply === supply)
      .sort((a, b) => (b.valveConnectionID || 0) - (a.valveConnectionID || 0));
    const lastConnection = connections[0];
    const lastValveConnectorGrid = valveConnectorGridAdapter
      .getSelectors()
      .selectById(componentState.valveConnectorGrids, lastConnection?.valveConnectorGridID || 0);
    const newValveConnectorGrid = valveConnectorGridAdapter
      .getSelectors()
      .selectAll(componentState.valveConnectorGrids)
      .find(
        (a) =>
          a.ductID === projectState.activeProject?.ductTypeID &&
          a.valveGridID === lastValveConnectorGrid?.valveGridID &&
          a.valveConnectionID === enValveConnectorConnection.rightAngled &&
          a.valveDiameterID === projectState.activeProject?.valveDiameterID
      );
    await dispatch(
      projectSlice.actions.addValveConnection({
        supply: supply,
        valveConnectorGridID: newValveConnectorGrid?.valveConnectorGridID,
        ductID: projectState.activeProject?.ductTypeID,
      })
    );
  };
};

/*
export const addConnectionThunk = (supply: PlenumDataSupply) => {
  return async (dispatch: any, getState: any) => {
    const componentState = selectComponentState(getState());
    const projectState = selectProjectState(getState());
    if (projectState.activeProject) {
      //get last add connection
      const connections = connectionAdapter
        .getSelectors()
        .selectAll(projectState.valveConnections)
        .filter((a) => a.supply === supply)
        .sort((a, b) => (b.valveConnectionID || 0) - (a.valveConnectionID || 0));
      const lastConnection = connections.length > 0 ? connections[0] : undefined;
      const lastValveConnectorGrid = valveConnectorGridAdapter
        .getSelectors()
        .selectById(componentState.valveConnectorGrids, lastConnection?.valveConnectorGridID || 0);

      const newValveConnectorGrid = valveConnectorGridAdapter
        .getSelectors()
        .selectAll(componentState.valveConnectorGrids)
        .find(
          (a) =>
            a.ductID === projectState.activeProject?.ductTypeID &&
            a.valveGridID === lastValveConnectorGrid?.valveGridID &&
            a.valveConnectionID === enValveConnectorConnection.rightAngled &&
            a.valveDiameterID === projectState.activeProject?.valveDiameterID
        );

      await dispatch(
        projectSlice.actions.connectionAdded({
          supply: supply,
          ductID: projectState.activeProject?.ductTypeID,
          valveConnectorGridID: newValveConnectorGrid?.valveConnectorGridID,
        })
      );
    }
  };
};
*/
