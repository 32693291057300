import { IDuct } from 'application/components/api/Duct';
import { IValveConnectorGrid } from 'application/components/api/ValveConnectorGrid';
import { PlenumDataSupply } from 'application/shared/PlenumDataSupply';

const maxFlowValue = 9999;
const CovertValue = (value: number) => (value === maxFlowValue ? 0 : value);

const getDebietBorders = (
  dual: boolean,
  supply: PlenumDataSupply,
  ducts: IDuct[],
  valveConnectorGrid: IValveConnectorGrid | undefined
) => {
  let ductMax = CovertValue(
    ducts.reduce((val, duct) => Math.min(val, supply ? duct?.flowSupplyMax : duct.flowExtractionMax), maxFlowValue)
  );

  let ductWarn = CovertValue(
    ducts.reduce((val, duct) => Math.min(val, supply ? duct?.flowSupplyWarn : duct.flowExtractionWarn), maxFlowValue)
  );
  let ductMin = ducts.reduce((val, duct) => Math.max(val, supply ? duct?.flowSupplyMin : duct.flowExtractionMin), 0);
  const valveMax = supply ? valveConnectorGrid?.supplyFlowMax : valveConnectorGrid?.extractionFlowMax;
  const valveMaxDuplex = supply ? valveConnectorGrid?.supplyFlowMaxDuplex : valveConnectorGrid?.extractionFlowMaxDuplex;
  const valveWarn = supply ? valveConnectorGrid?.supplyFlowWarn : valveConnectorGrid?.extractionFlowWarn;
  const valveWarnDuplex = supply
    ? valveConnectorGrid?.supplyFlowWarnDuplex
    : valveConnectorGrid?.extractionFlowWarnDuplex;

  if (dual) ductMax *= 2;
  if (dual) ductWarn *= 2;

  if (valveConnectorGrid && valveMax && ductMax > valveMax) ductMax = valveMax;
  if (valveConnectorGrid && valveMaxDuplex && dual && ductMax > valveMaxDuplex) ductMax = valveMaxDuplex;
  if (valveConnectorGrid && valveWarn && ductMax > valveWarn) ductWarn = valveWarn;
  if (valveConnectorGrid && valveWarnDuplex && dual && ductWarn > valveWarnDuplex) ductWarn = valveWarnDuplex;

  ductWarn = ductWarn || ductMax - 0.001;

  return { ductMin, ductWarn, ductMax };
};

export default getDebietBorders;
