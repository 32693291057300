import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useTheme } from '@material-ui/core';

export const RadioSelector = (props: {
  value: number | undefined;
  name: string;
  label?: string;
  valueFieldName: string;
  displayFieldName: string;
  options: any[];
  onChange: (value: number | undefined, name: string, error: string) => any;
}) => {
  const { onChange } = props;
  const theme = useTheme();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value =
        (event.target as HTMLInputElement).value === ''
          ? undefined
          : parseInt((event.target as HTMLInputElement).value);
      onChange(value, props.name, '');
    },
    [onChange, props.name]
  );

  return (
    <FormControl component="fieldset">
      {props.label ? (
        <FormLabel component="legend" style={{ marginRight: theme.spacing(2) }}>
          {props.label}
        </FormLabel>
      ) : null}
      <RadioGroup aria-label="gender" name={props.name} value={props.value?.toString() || ''} onChange={handleChange}>
        {props.options.map((a) => (
          <FormControlLabel
            key={a[props.valueFieldName]}
            value={a[props.valueFieldName].toString()}
            control={<Radio />}
            label={a[props.displayFieldName]}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
