import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDuctAdapters } from 'application/components/api/DuctAdapter';
import { selectComponentState } from 'redux/store';

export const fetchAsyncDuctAdapters = createAsyncThunk(
  'components/fetchAsyncDuctAdapters',
  async (data: {}, thunkAPI) => {
    const response = await getDuctAdapters();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectComponentState(thunkAPI.getState()).ductadapters.loading === 'init';
    },
  }
);
