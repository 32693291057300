import React from 'react';

type windowSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const width2text = (width: number): windowSize => {
  if (width < 576) return 'xs';
  if (width < 768) return 'sm';
  if (width < 992) return 'md';
  if (width < 1200) return 'lg';
  if (width < 1400) return 'xl';
  return 'xxl';
};

export const useWindowSize = () => {
  const isSSR = typeof window !== 'undefined';
  const [windowSize, setWindowSize] = React.useState<windowSize>(isSSR ? 'xl' : width2text(window.innerWidth));
  const windowSizeRef = React.useRef<windowSize>(windowSize);

  const changeWindowSize = React.useCallback(() => {
    const newVal = width2text(window.innerWidth);
    if (windowSizeRef.current !== newVal) {
      windowSizeRef.current = newVal;
      setWindowSize(newVal);
    }
  }, [setWindowSize]);

  React.useEffect(() => {
    window.addEventListener('resize', changeWindowSize);
    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, [changeWindowSize]);

  return windowSize;
};
