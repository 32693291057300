import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { IProject } from 'application/project/api/Project';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%',
      marginTop: theme.spacing(2),
    },
    button: {
      float: 'right',
    },
  })
);

export const ProjectItem = (props: {
  project: IProject;
  editProject: (project: IProject) => any;
  copyProject: (project: IProject) => any;
  deleteProject: (project: IProject) => any;
}) => {
  const classes = useStyles();
  const { editProject, copyProject, deleteProject } = props;
  const { t } = useTranslation(['project']);

  const date = new Date(props.project.date);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <div>
              <div>
                <h3>{props.project?.title}</h3>
                {t('projectDateFormat', '{{val, shortdate}}', {
                  val: date,
                })}
              </div>
              <div>{props.project?.description}</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <IconButton
              className={classes.button}
              color="primary"
              title={t('editbuttontitle', 'edit project')}
              onClick={React.useCallback(
                (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  editProject(props.project);
                },
                [editProject, props?.project]
              )}>
              <EditIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              color="primary"
              title={t('copybuttontitle', 'copy project')}
              onClick={React.useCallback(
                (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  copyProject(props.project);
                },
                [copyProject, props.project]
              )}>
              <FileCopyIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              color="primary"
              title={t('deletebuttontitle', 'delete project')}
              onClick={React.useCallback(
                (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  deleteProject(props.project);
                },
                [deleteProject, props.project]
              )}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ProjectItem;
