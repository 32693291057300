import * as React from 'react';
import PageTitle from './PageTitle';

const RegularPage = (props: { children?: any; title: string }) => {
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        paddingLeft: 15,
        paddingRight: 15,
      }}>
      <PageTitle title={props.title} />
      <main>{props.children}</main>
    </div>
  );
};

export default RegularPage;
