export enum ImageType {
  plenum = 1,
  valvegrid,
  extracomponent,
  dealer,
  valveconnectorgrid,
}

export const getImage = (imageID: number, imageType: ImageType) => {
  return imageID ? 'api/Image/' + imageType + '/' + imageID : '';
};

export const getValveImage = (imageID: number) => {
  return getImage(imageID, ImageType.valvegrid);
};

export const getPlenumImage = (imageID: number) => {
  return getImage(imageID, ImageType.plenum);
};

export const getExtraComponentImage = (imageID: number) => {
  return getImage(imageID, ImageType.extracomponent);
};
