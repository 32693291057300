import { selectComponentState } from 'redux/store';
import { ductAdapter } from '../adapters/ductAdapter';

export enum enDuctType {
  round92 = 1,
  round75 = 2,
  oval = 3,
}

export const {
  selectAll: selectAllDucts,
  selectById: selectDuctById,
  selectEntities: selectDuctEntities,
} = ductAdapter.getSelectors((state) => selectComponentState(state).ducts);
