import { PlenumDataInside, PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ComponentList from '../ComponentList/ComponentList';
import EppDataEdit from '../EppDataEdit/EppDataEdit';
import ExtraComponents from '../ExtraComponents/ExtraComponents';
import InstallationPlan from '../InstallationPlan/InstallationPlan';
import { PlenumSelector } from '../PlenumSelector/PlenumSelector';
import ProjectSettings from '../ProjectSettings/ProjectSettings';
import RoomEditor from '../RoomEditor/RoomEditor';

import additionalComponents from 'image/menu/Icon-Additional components.svg';
import airExtraction from 'image/menu/Icon-Air extraction.svg';
import airSupply from 'image/menu/Icon-Air supply.svg';
import componentList from 'image/menu/Icon-Component list.svg';
import installationList from 'image/menu/Icon-Installation list.svg';
import projectSettings from 'image/menu/Icon-Project settings.svg';
import startProject from 'image/menu/Icon-Start project.svg';

import a1png from 'image/overview/A1.png';
import a2png from 'image/overview/A2.png';
import a3png from 'image/overview/A3.png';
import a4png from 'image/overview/A4.png';
import a5png from 'image/overview/A5.png';
import a6png from 'image/overview/A6.png';
import a7png from 'image/overview/A7.png';
//import a8png from 'image/overview/A8.png'; //wtw unit nog niet
import a9png from 'image/overview/A9.png';

import b1png from 'image/overview/B1.png';
import b2png from 'image/overview/B2.png';
import b3png from 'image/overview/B3.png';
//import b4png from 'image/overview/B4.png'; //wtw unit nog niet
import b5png from 'image/overview/B5.png';

import { useTranslation } from 'react-i18next';
import { IEditorTabs } from './IEditorTabs';

export const pages_project_list = '/project/list';
export const pages_project_setactive = '/project/setactive/';
export const pages_project_edit = '/project/edit';
export const pages_project_supplyplenum = '/project/edit/supply';
export const pages_project_supplyroom = '/project/edit/supply/room';
export const pages_project_supplymvhrplenum = '/project/edit/supply/mvhrplenum';
export const pages_project_supplyoutmvhr = '/project/edit/supply/outmvhr';
export const pages_project_extractionplenum = '/project/edit/extraction';
export const pages_project_extractionroom = '/project/edit/extraction/room';
export const pages_project_extractionplenummvhr = '/project/edit/extraction/plenummvhr';
export const pages_project_extractionmvhrout = '/project/edit/extraction/mvhrout';
export const pages_project_extracomponents = '/project/edit/extracomponents';
export const pages_project_componentlist = '/project/edit/componentlist';
export const pages_project_installationplan = '/project/edit/installationplan';
export const pages_project_calculation = '/project/edit/calculation';

export const useRoutes = (
  extraction: boolean,
  roles: string[]
): [IEditorTabs[], IEditorTabs[][], number, string | undefined, () => any, boolean, () => any, boolean] => {
  const { t } = useTranslation(['editortabs']);
  const location = useLocation();
  const history = useHistory();

  const baseEditorTabs: IEditorTabs[] = React.useMemo(
    () => [
      {
        title: t('projectsettings', 'Project settings'),
        url: pages_project_edit,
        exact: true,
        component: undefined,
        icon: projectSettings,
      },
      {
        title: t('airsupply', 'Air supply'),
        url: pages_project_supplyplenum,
        exact: true,
        component: undefined,
        icon: airSupply,
        supply: true,
        balansimage: a2png,
      },
      {
        title: t('airsupplyrooms', 'Rooms for air supply'),
        url: pages_project_supplyroom,
        urlGroup: pages_project_supplyplenum,
        component: undefined,
        supply: true,
        balansimage: a3png,
      },
      {
        title: t('airsupplymvhrplenum', 'Duct component between plenum & MVHR for air supply'),
        url: pages_project_supplymvhrplenum,
        urlGroup: pages_project_supplyplenum,
        component: undefined,
        supply: true,
        balansimage: a4png,
      },
      {
        title: t('airsupplyoutmvhr', 'Duct component between MVHR & roof/wall terminal for air supply'),
        url: pages_project_supplyoutmvhr,
        urlGroup: pages_project_supplyplenum,
        component: undefined,
        supply: true,
        balansimage: a9png,
      },
      {
        title: t('airextraction', 'Air extraction'),
        url: pages_project_extractionplenum,
        exact: true,
        component: undefined,
        icon: airExtraction,
        balansimage: a6png,
        extractimage: b2png,
      },
      {
        title: t('airextractionrooms', 'Rooms for air extraction'),
        url: pages_project_extractionroom,
        urlGroup: pages_project_extractionplenum,
        component: undefined,
        balansimage: a5png,
        extractimage: b1png,
      },
      {
        title: t('airextractionplenummvhr', 'Duct component between plenum & MVHR for air extraction'),
        url: pages_project_extractionplenummvhr,
        urlGroup: pages_project_extractionplenum,
        component: undefined,
        balansimage: a7png,
        extractimage: b3png,
      },
      {
        title: t('airextractionmvhrout', 'Duct component between MVHR & roof/wall terminal for air extraction'),
        url: pages_project_extractionmvhrout,
        urlGroup: pages_project_extractionplenum,
        component: undefined,
        balansimage: a1png,
        extractimage: b5png,
      },
      {
        title: t('extracomponents', 'Additional components'),
        url: pages_project_extracomponents,
        component: undefined,
        icon: additionalComponents,
      },
      {
        title: t('componentlist', 'Components list'),
        url: pages_project_componentlist,
        component: undefined,
        icon: componentList,
      },
      {
        title: t('installationplan', 'Installation plan'),
        url: pages_project_installationplan,
        component: undefined,
        icon: installationList,
      },
      {
        title: 'Calculation report',
        url: pages_project_calculation,
        component: undefined,
        icon: installationList,
        role: 'Calculator',
      },
    ],
    [t]
  );

  const theEditorTabs: IEditorTabs[] = React.useMemo(
    () => baseEditorTabs.filter((a) => (!extraction || a.supply !== true) && (!a.role || roles.includes(a.role))),
    [baseEditorTabs, extraction, roles]
  );

  const listEditorTabs = React.useMemo(
    () => [{ title: t('projectlist', 'Project list'), url: pages_project_list, icon: startProject }] as IEditorTabs[],
    [t]
  );

  const editorTabs = React.useMemo(() => [listEditorTabs, theEditorTabs], [theEditorTabs, listEditorTabs]);

  const currentIndex = React.useMemo(
    () => theEditorTabs.findIndex((a) => a.url.toLowerCase() === location.pathname.toLowerCase()),
    [location.pathname, theEditorTabs]
  );

  const onPrev = React.useCallback(
    () => history.replace(theEditorTabs[currentIndex - 1].url),
    [currentIndex, theEditorTabs, history]
  );
  const onNext = React.useCallback(
    () => history.replace(theEditorTabs[currentIndex + 1].url),
    [currentIndex, theEditorTabs, history]
  );
  const canPrev = React.useMemo(() => currentIndex > 0, [currentIndex]);
  const canNext = React.useMemo(() => currentIndex < theEditorTabs.length - 1, [currentIndex, theEditorTabs]);

  const populatedEditorTabs = React.useMemo(() => {
    theEditorTabs.forEach((item) => {
      switch (item.url) {
        case pages_project_edit:
          item.component = <ProjectSettings onNext={onNext} />;
          break;
        case pages_project_supplyplenum:
          item.component = <PlenumSelector supply={PlenumDataSupply.supply} onNext={onNext} />;
          break;
        case pages_project_supplyroom:
          item.component = <RoomEditor supply={PlenumDataSupply.supply} onNext={onNext} />;
          break;
        case pages_project_supplymvhrplenum:
          item.component = (
            <EppDataEdit supply={PlenumDataSupply.supply} inside={PlenumDataInside.inside} onNext={onNext} />
          );
          break;
        case pages_project_supplyoutmvhr:
          item.component = (
            <EppDataEdit supply={PlenumDataSupply.supply} inside={PlenumDataInside.outside} onNext={onNext} />
          );
          break;
        case pages_project_extractionplenum:
          item.component = <PlenumSelector supply={PlenumDataSupply.extraction} onNext={onNext} />;
          break;
        case pages_project_extractionroom:
          item.component = <RoomEditor supply={PlenumDataSupply.extraction} onNext={onNext} />;
          break;
        case pages_project_extractionplenummvhr:
          item.component = (
            <EppDataEdit supply={PlenumDataSupply.extraction} inside={PlenumDataInside.inside} onNext={onNext} />
          );
          break;
        case pages_project_extractionmvhrout:
          item.component = (
            <EppDataEdit supply={PlenumDataSupply.extraction} inside={PlenumDataInside.outside} onNext={onNext} />
          );
          break;
        case pages_project_extracomponents:
          item.component = <ExtraComponents onNext={onNext} />;
          break;
        case pages_project_componentlist:
          item.component = <ComponentList onNext={onNext} />;
          break;
        case pages_project_installationplan:
          item.component = <InstallationPlan onNext={onNext} />;
          break;
        case pages_project_calculation:
          item.component = <InstallationPlan calculation onNext={onNext} />;
          break;
        default:
          item.component = <div>??</div>;
          break;
      }
    });
    return theEditorTabs;
  }, [theEditorTabs, onNext]);

  return [
    populatedEditorTabs,
    editorTabs,
    currentIndex,
    currentIndex >= 0
      ? extraction
        ? populatedEditorTabs[currentIndex].extractimage
        : populatedEditorTabs[currentIndex].balansimage
      : undefined,
    onPrev,
    canPrev,
    onNext,
    canNext,
  ];
};
