import * as React from 'react';
import { IValveConnection, SECTIONS_AS_PLENUMCONNECTION_0 } from 'application/project/api/Connection';
import { PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import { useSelector } from 'react-redux';
import { makeSelectSectionsByConnection } from 'application/project/redux/selectors/sectionSelectors';
import { selectValveConnectionByKey } from 'application/project/redux/selectors/connectionSelectors';
import getDebietBorders from 'application/project/validate/bordersDebiet';
import { selectValveConnectorGridById } from 'application/components/redux/selectors/valveConnectorGridSelector';
import { selectAllDucts } from 'application/components/redux/selectors/ductSelector';

const useConnectionFlowLimits = (valveConnectionKey: string) => {
  const valveConnection =
    useSelector((state) => selectValveConnectionByKey(state, valveConnectionKey)) ||
    ({ supply: PlenumDataSupply.supply, dual: false } as IValveConnection);

  const { supply, dual } = valveConnection;
  const selectSectionsByConnection = React.useMemo(makeSelectSectionsByConnection, []);
  const sections = useSelector((state) =>
    selectSectionsByConnection(state, valveConnectionKey, SECTIONS_AS_PLENUMCONNECTION_0)
  );
  const allDucts = useSelector(selectAllDucts);
  const ducts = React.useMemo(
    () => allDucts.filter((duct) => sections.find((a) => a.ductID === duct.ductID)),
    [sections, allDucts]
  );
  const valveConnectorGrid = useSelector((state) =>
    selectValveConnectorGridById(state, valveConnection?.valveConnectorGridID || 0)
  );
  const { ductMin, ductWarn, ductMax } = React.useMemo(
    () => getDebietBorders(dual, supply, ducts, valveConnectorGrid),
    [supply, dual, ducts, valveConnectorGrid]
  );

  return { ductMin, ductWarn, ductMax };
};

export default useConnectionFlowLimits;
