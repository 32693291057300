import React, { Component } from 'react';
import { Container, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
//import './NavMenu.css';
import mainLogo from 'image/burgerhout.png';
import i18n from 'i18next';

import authService from './api-authorization/AuthorizeService';
import clsx from 'clsx';

//https:codewithmukesh.com/blog/identityserver4-in-aspnet-core/
//https://docs.identityserver.io/en/latest/intro/big_picture.html
//https://docs.identityserver.io/en/latest/quickstarts/4_javascript_client.html
//https://github.com/iammukeshm/GettingStartedWithIdentityServer4.ASPNETCore
//https://medium.com/@piotrkarpaa/using-spa-react-angular-ui-with-identity-server-4-dc1f57e90b2c

//https://clintmcmahon.com/there-was-an-error-running-the-selected-code-generator-in-net-core-5/
//dotnet aspnet-codegenerator identity -dc HyBalansPlus.Data.HyBalansPlusContext

//https://www.scottbrady91.com/OpenID-Connect/Silent-Refresh-Refreshing-Access-Tokens-when-using-the-Implicit-Flow
//https://stackoverflow.com/questions/50363450/identityserver4-access-token-lifetime

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      isAuthenticated: false,
      userName: null,
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
    this.setState({
      isAuthenticated,
      userName: user && user.name,
    });
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  /*
      <Collapse className="d-sm-inline-flex flex-sm-row" isOpen={!this.state.collapsed} navbar>
      </Collapse>
    */

  render() {
    console.log('NavMenu', { props: this.props, state: this.state });
    const { isAuthenticated } = this.state;
    return (
      <header className="main">
        <div className={clsx('myborder', this.props.homePage && !isAuthenticated ? 'home' : null)}>
          <Container>
            <div className="clearfix"></div>
            <div className="headerWrapper">
              <a className="brand" href="/">
                <img src={mainLogo} alt={'logo'} />
              </a>
              <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white nav-primary" light>
                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                <div className="menu-hoofdnavigatie-container">
                  <ul className="nav mggroup flex-grow">
                    {/* <NavItem>
                      <NavLink tag={Link} to="/">
                        {isAuthenticated ? i18n.t('home:projectLink', 'Projects') : i18n.t('home:homeLink', 'Home')}
                      </NavLink>
                    </NavItem> */}
                    <LoginMenu
                      languages={this.props.languages}
                      currentLanguage={this.props.currentLanguage}
                      onChangeLanguage={this.props.onChangeLanguage}
                    />
                  </ul>
                </div>
              </Navbar>
              <div className="clearfix"></div>
            </div>
          </Container>
        </div>
      </header>
    );
  }
}

/*
                <img
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'top',
                    height: '100%',
                  }}
                  src={headervision}
                  alt="Burgerhout HybalansSystems"
                />
*/
