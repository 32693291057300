import { RadioSelector } from 'components/controls/RadioSelector';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const VentilationSystemSelector = (props: {
  value: number | undefined;
  name: string;
  onChange: (value: number | undefined, name: string, error: string) => any;
}) => {
  const { t } = useTranslation(['project']);
  const options = React.useMemo(
    () => [
      { id: 1, name: t('balancedVentilation', 'balanced ventilation') },
      { id: 2, name: t('extractVentilation', 'extract ventilation') },
    ],
    [t]
  );

  return (
    <RadioSelector
      label={t('ventilationtype', 'ventilation type')}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      valueFieldName="id"
      displayFieldName="name"
      options={options}
    />
  );
};
