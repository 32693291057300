import axios from 'axios';

export const supportedLanguages = ['en', 'nl', 'de', 'fr', 'it', 'pl' /*, 'hu'*/];
function getCookie(cname: string) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const getLanguage = () => {
  const value = getCookie('.AspNetCore.Culture');
  let language = '';
  if (value.length) {
    const parts = value.split('|');
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].indexOf('c=') === 0) {
        language = parts[i].substring(2);
        break;
      }
    }
  }
  if (supportedLanguages.indexOf(language) === -1) {
    if (supportedLanguages.indexOf(navigator.language) !== -1) {
      language = navigator.language;
    } else {
      language = 'en';
    }
  }
  return language;
};

export const setBackendLanguage = async (language: string) => {
  const response = await axios.post('api/Localisation/' + language);
  return response.data;
};
