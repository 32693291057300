import { createAsyncThunk } from '@reduxjs/toolkit';
import { getValveConnectorGridsEdit } from 'application/editcomp/api/ValveConnectorGrid/ValveConnectorGridEdit';
import { selectCompEditState } from 'redux/store';

export const fetchAsyncValveConnectorGridsEdit = createAsyncThunk(
  'editcomp/fetchAsyncValveConnectorGridsEdit',
  async (data: {}, thunkAPI) => {
    const response = await getValveConnectorGridsEdit();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectCompEditState(thunkAPI.getState()).valveConnectorGrids.loading === 'init';
    },
  }
);
