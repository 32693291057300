import { enDuctType } from 'application/components/redux/selectors/ductSelector';
import { RadioSelector } from 'components/controls/RadioSelector';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const DuctTypeSelector = (props: {
  value: number | undefined;
  name: string;
  onChange: (value: number | undefined, name: string, error: string) => any;
}) => {
  const { t } = useTranslation(['project']);
  const options = React.useMemo(
    () => [
      { ductID: enDuctType.round92 as number, name: t('ducttype_round', 'round') },
      { ductID: enDuctType.oval as number, name: t('ducttype_oval', 'oval') },
    ],
    [t]
  );

  return (
    <RadioSelector
      label={t('ducttype', 'duct type')}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      valueFieldName="ductID"
      displayFieldName="name"
      options={options}
    />
  );
};
