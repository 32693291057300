import { IValveConnection } from 'application/project/api/Connection';
import { IPlenumData } from 'application/project/api/PlenumData';
import { componentState } from 'application/components/redux/componentState';
import { projectState } from 'application/project/redux/projectState';
import GetApiConnection from '../redux/thunks/helper/GetApiConnection';
import getApiValveConnectionError from './getApiValveConnectionError';

const getValveConnectionError = (
  valveConnection: IValveConnection,
  plenumData: IPlenumData,
  projectState: projectState,
  componentState: componentState
) => {
  return getApiValveConnectionError(
    GetApiConnection(valveConnection, projectState, componentState),
    plenumData,
    projectState.activeProject?.experimental || false,
    projectState,
    componentState
  );
};

export default getValveConnectionError;
