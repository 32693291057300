import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BaseDialog, { BaseButtonType } from './BaseDialog';
import { CorfirmOpenState } from './useConfirmDialog';

export default function ConfirmDialog(props: CorfirmOpenState) {
  const { t } = useTranslation(['translation']);
  return (
    <BaseDialog
      IsOpen={props.isOpen}
      title={props.title}
      onClose={props.onClose}
      onApply={() => {
        props.onClose();
        props.onConfirm();
      }}
      buttons={[
        {
          label: t('nobutton', 'no'),
          type: BaseButtonType.close,
        },
        {
          label: t('yesbutton', 'yes'),
          type: BaseButtonType.submit,
        },
      ]}>
      {props.question}
    </BaseDialog>
  );
}
