import {
  IApiValveConnection,
  IValveConnection,
  SECTIONS_AS_PLENUMCONNECTION_0,
} from 'application/project/api/Connection';
import { componentState } from 'application/components/redux/componentState';
import { projectState } from '../../projectState';
import { plenumConnectionAdapter } from '../../adapters/plenumConnectionAdapter';
import { sectionAdapter } from '../../adapters/sectionAdapter';
import { sectionBendAdapter } from '../../adapters/sectionBendAdapter';
import { bendAdapter } from 'application/components/redux/adapters/bendAdapter';

const GetApiConnection = (
  valveConnection: IValveConnection,
  projectState: projectState,
  componentState: componentState
): IApiValveConnection => ({
  ...valveConnection,
  plenumConnections: plenumConnectionAdapter
    .getSelectors()
    .selectAll(projectState.plenumConnections)
    .filter((a) => a.valveConnectionKey === valveConnection.valveConnectionKey)
    .map((plenumConnection) => ({
      ...plenumConnection,
      sections: sectionAdapter
        .getSelectors()
        .selectAll(projectState.sections)
        .filter((a) => a.valveConnectionKey === valveConnection.valveConnectionKey)
        .filter(
          (a) =>
            a.valveConnectionKey === plenumConnection.valveConnectionKey &&
            a.plenumConnectionIndex ===
              SECTIONS_AS_PLENUMCONNECTION_0 /* secties altijd op plenumConnection=0 plenumConnection.indexNumber*/
        )
        .map((section) => ({
          ...section,
          bends: sectionBendAdapter
            .getSelectors()
            .selectAll(projectState.sectionbends)
            .filter((a) => a.valveConnectionKey === plenumConnection.valveConnectionKey && a.amount > 0)
            .filter((a) => {
              const bend = bendAdapter.getSelectors().selectById(componentState.bends, a.bendID);
              return (
                a.valveConnectionKey === section.valveConnectionKey &&
                a.plenumConnectionIndex === section.plenumConnectionIndex &&
                a.sectionIndex === section.indexNumber &&
                bend?.ductID === section.ductID //komt ductID nog wel overeen
              );
            }),
        })),
    })),
});

export default GetApiConnection;
